import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { useJourneyStepStats } from 'api/Journeys/journey_queries';
import { LocalAtmTwoTone, GroupTwoTone, BackspaceTwoTone } from '@mui/icons-material';

const Stats = ({ stats }) => {
    const [ totalConversions, setTotalConversions ] = React.useState(0);
    const [ totalExited, setTotalExited ] = React.useState(0);

    React.useEffect(() => {
        if(stats) {
            let total = 0;
            Object.entries(stats?.conversions).forEach(([key, value]) => {
                total = total + value;
                return true;
            });
            setTotalConversions(total);
            setTotalExited(stats?.global_dropout_filter.skipped + stats?.bypass_filter.skipped);
        }
    }, [stats]);


    return (
        <Box className='flow-sidebar__body-hihlighter' display='flex' style={{padding: '5px 0px'}}>
            <Box sx={{width: '100%'}} display='flex' flexDirection='column'>
                <Box display='flex' flexGrow={1} alignItems='center' mb={2}>
                    <GroupTwoTone style={{marginRight: 10}}/>
                    <Typography component='div' variant='subtitle1'>Total Travellers: <span style={{fontWeight: 400}}>{stats.step.journeyed}</span></Typography>
                </Box>
                <Box display='flex' flexGrow={1} alignItems='center' mb={2}>
                    <BackspaceTwoTone style={{marginRight: 10}}/>
                    <Typography component='div' variant='subtitle1'>Exited Because of Journey Filter: <span style={{fontWeight: 400}}>{totalExited}</span></Typography>
                </Box>
                <Box display='flex' flexGrow={1} alignItems='center' mb={2}>
                    <LocalAtmTwoTone style={{marginRight: 10}}/>
                    <Typography component='div' variant='subtitle1'>Converted while Waiting: {totalConversions}</Typography>
                </Box>
            </Box>
        </Box>
    );
};

const WaitSidebar = ({ stepId }) => {
    const { id } = useParams();
    const { status, data, error, isFetching } = useJourneyStepStats(id, stepId);

    return (
        <Box className='flow-sidebar__body' p={0}>
            <Box className='flow-sidebar__body-content'>
                {status === 'loading' ? (
                    'Loading...'
                ) : status === 'error' ? (
                    <span>Error: {error.message}</span>
                ) : (
                    <>{isFetching ? 'Background Updating...' : <Stats stats={data} />}</>
                )}
            </Box>
        </Box>
    );
};

export default WaitSidebar;
