import { initialState, neutralState } from '../helpers/defaultStateHelpers';
import { FETCH_ORGANIZATIONS_START, FETCH_ORGANIZATIONS_SUCCESS, FETCH_ORGANIZATIONS_ERROR, POST_ORGANIZATIONS_START, POST_ORGANIZATIONS_SUCCESS, POST_ORGANIZATIONS_ERROR } from './organizations_types';

const fetchOrganizationsReducer = (state = { ...initialState }, action) => {
    switch (action.type) {
    case FETCH_ORGANIZATIONS_START:
        return { ...state, fetching: true };
    case FETCH_ORGANIZATIONS_SUCCESS:
        return { ...state, ...neutralState, ready: true, fetched: true, data: action.data || [] };
    case FETCH_ORGANIZATIONS_ERROR:
        return { ...state, ...neutralState, error: action.error };
    case POST_ORGANIZATIONS_START:
        return { ...state, fetching: true };
    case POST_ORGANIZATIONS_SUCCESS:
        return { ...state, ...neutralState, ready: true, fetched: true, data: action.data || [] };
    case POST_ORGANIZATIONS_ERROR:
        return { ...state, ...neutralState, error: action.error };
    default:
        return state;
    }
};

export default fetchOrganizationsReducer;
