import React from 'react';
import { ListItemIcon } from '@mui/material';
import logoFull from 'assets/icons/main/el_logo_full.svg';
import logoShort from 'assets/icons/main/el_logo_icon.svg';
import { withRouter } from 'react-router';

const Logo = ({ open, history }) => {
    return (
        <ListItemIcon className='elemental_logo'>
            {open ? (
                <img src={logoFull} alt='Elemental' onClick={() => history.push('/campaigns')} />
            ) : (
                <img src={logoShort} alt='Elemental' onClick={() => history.push('/campaigns')} />
            )}
        </ListItemIcon>
    );
};

export default withRouter(Logo);
