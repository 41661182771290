import { initialState, neutralState } from '../helpers/defaultStateHelpers';
import { SHOW_CONTEXT_START, SHOW_CONTEXT_SUCCESS, SHOW_CONTEXT_ERROR } from './context_types';

const fetchContextReducer = (state = { ...initialState }, action) => {
    switch (action.type) {
    case SHOW_CONTEXT_START:
        return { ...state, fetching: true };
    case SHOW_CONTEXT_SUCCESS:
        return { ...state, ...neutralState, ready: true, fetched: true, data: action.data || [] };
    case SHOW_CONTEXT_ERROR:
        return { ...state, ...neutralState, error: action.error };
    default:
        return state;
    }
};

export default fetchContextReducer;
