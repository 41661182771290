import React, { createContext, useState, useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';


const UnexpectedErrorDialogContext = createContext();

export function useUnexpectedErrorDialog () {
    return useContext(UnexpectedErrorDialogContext);
}

export function UnexpectedErrorDialogProvider ({ children }) {
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const showUnexpectedError = (message) => {
        setErrorMessage(message);
        setErrorDialogOpen(true);
    };

    return (
        <UnexpectedErrorDialogContext.Provider value={showUnexpectedError}>

            <Dialog open={errorDialogOpen} onClose={() => setErrorDialogOpen(false)}>
                <DialogTitle>Error</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {errorMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setErrorDialogOpen(false)} color='primary'>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            {children}
        </UnexpectedErrorDialogContext.Provider>
    );
}
