import { useQuery } from '@tanstack/react-query';
import * as http from 'util/elemental_axios_client';
import {API_FLOWS_ROUTE, API_MESSAGES_ROUTE, apiV1OrgUri} from 'constants/apiRoutes';

export const useJourney = (id, options ) => {
    return useQuery(['journey', id], async () => {
        return await http.get(`${apiV1OrgUri}/flows/${id}`);
    }, options);
};

export const useJourneyStepStats = (id, stepId) => {
    const action = `${API_FLOWS_ROUTE}/${id}/steps/${stepId}/stats`;

    return useQuery(['journey_step_stats', stepId], async () => {
        return await http.get(action);
    });
};

export const useJourneyStep = (id, stepId) => {
    const action = `${API_FLOWS_ROUTE}/${id}/steps/${stepId}`;

    return useQuery(['journey_step', stepId], async () => {
        return await http.get(action);
    });
};

export const useAdjacencyList = (id, active) => {
    const status = !active ? 'draft' : 'active';
    const action = `${API_FLOWS_ROUTE}/${id}/${status}/adjacency_list`;

    return useQuery(['adjacencyList', id, active], async () => {
        return await http.get(action);
    });
};

export const useActiveAdjacencyList = (id) => {
    const action = `${API_FLOWS_ROUTE}/${id}/active/adjacency_list`;

    return useQuery(['activeAdjacencyList', id], async () => {
        return await http.get(action);
    });
};

export const useAdjacencyVersions = (id, date) => {
    const action = `${API_FLOWS_ROUTE}/${id}/active/adjacency_list/versions/${date}}`;

    return useQuery(['journeyVersion', id], async () => {
        return await http.get(action);
    });
};
