import React, { useEffect } from 'react';
import EdgeLabelRenderer, { BaseEdge, getSmoothStepPath } from 'reactflow';
import {Tooltip, Chip, Typography} from '@mui/material';
import { AddTwoTone, CachedTwoTone }  from '@mui/icons-material';

const foreignObjectSize = 75;

const AppendEdge = (props) => {
    const {
        id, label, data,
        source, target, selected,
        sourceX, sourceY, targetX, targetY,
        sourcePosition, targetPosition, markerEnd,
        animated,
        style = {},
        sourceHandle
    } = props;
    const { isLoading, sourceId, isDisabled } = data;

    const borderRadius = 20;
    const offset = 0;

    const [edgePath, labelX, labelY ] = getSmoothStepPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
        borderRadius,
        offset
    });

    // const [edgeCenterX, edgeCenterY] = getEdgeCenter({ sourceX, sourceY, targetX, targetY });


    useEffect(() => {

    }, [sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition]);

    return (
        <>

            <BaseEdge id={id} style={style} className={!isDisabled ? 'react-flow__smoothstep-edge' : 'react-flow__smoothstep-edge disabled'} path={edgePath} markerEnd={markerEnd} {...props}/>
            <EdgeLabelRenderer>
                <div
                    className={!isDisabled ? 'edgebutton-foreignobject' : 'edgebutton-foreignobject disabled'}
                    style={{
                        cursor: 'pointer',
                        position: 'absolute !important',
                        transform: `translate(-50%, -50%) translate(${sourceX}px,${sourceY+15}px)`,
                        // everything inside EdgeLabelRenderer has no pointer events by default
                        // if you have an interactive element, set pointer-events: all
                        pointerEvents: 'all'
                    }}
                >
                    <Tooltip placement='top' title='Add Step'>
                        <button className={isLoading ? 'edgebutton hover' : 'edgebutton'} data-step='add' data-handle={sourceHandle} data-source={sourceId} data-target={target} data-edge={id}>
                            <span className='IconContainer'>
                                {isLoading ? <CachedTwoTone className='isDropped hover' style={{color: '#FFF', margin: 'auto', fontSize: '16px' }} /> : <AddTwoTone style={{margin: 'auto'}} />}
                            </span>
                        </button>
                    </Tooltip>
                </div>
            </EdgeLabelRenderer>
            {label && label === 'MATCH' &&
                <EdgeLabelRenderer>
                    <div
                        className={!isDisabled ? 'edgebutton-foreignobject badge' : 'edgebutton-foreignobject badge disabled'}
                        style={{
                            cursor: 'pointer',
                            position: 'absolute !important',
                            transform: `translate(-55%, -50%) translate(${labelX-1}px,${labelY-5}px)`,
                            // everything inside EdgeLabelRenderer has no pointer events by default
                            // if you have an interactive element, set pointer-events: all
                            pointerEvents: 'all'
                        }}
                    >
                        <Chip size='small' className='branch-chip' label={label} />
                    </div>
                </EdgeLabelRenderer>
            }
            {label && label === 'NO MATCH' &&
                <EdgeLabelRenderer>
                    <div
                        className={!isDisabled ? 'edgebutton-foreignobject badge' : 'edgebutton-foreignobject badge disabled'}
                        style={{
                            cursor: 'pointer',
                            position: 'absolute !important',
                            transform: `translate(-40%, -50%) translate(${labelX}px,${labelY-5}px)`,
                            // everything inside EdgeLabelRenderer has no pointer events by default
                            // if you have an interactive element, set pointer-events: all
                            pointerEvents: 'all'
                        }}
                    >
                        <Chip size='small' className='branch-chip' label={label} />
                    </div>
                </EdgeLabelRenderer>
            }
        </>
    );
};

export default AppendEdge;
