import * as http from '../../util/elemental_axios_client';
import { API_SETTINGS_NAME_ROUTE } from 'constants/apiRoutes';
const orgPublicUrl = `${process.env.REACT_APP_API_URL}`;

export const getOrganizations  = (id) => http.get(`${orgPublicUrl}/api/v1/identities/current/organizations`);
export const postOrganization  = (request) => http.post(`${orgPublicUrl}/api/v1/organizations`, request);
// export const updateVersion = (id, payload) => http.put(`${API_VERSIONS_ROUTE}/${id}`, payload);

export function createOrganizationSettingsForm (formData, cbSuccess, cbError) {
    return http.post(
        API_SETTINGS_NAME_ROUTE,
        formData,
        cbSuccess ? (response) => cbSuccess(response.data) : null,
        cbError ? (response) => cbError(response.data) : null
    );
}
