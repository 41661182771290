import React from 'react';
import { CampaignSidebar } from '../../organisms/CampaignStack';
import { JourneySidebar, JourneyIssuesSidebar, JourneyReportSidebar } from '../../organisms/JourneyStack';
import { DeliveryReportSidebar } from '../../organisms/DashboardStack';
import { CreateJourney, DropoutSidebar, TriggerSidebar } from 'components/molecules/Journeys/Sidebar';
import { useParams, withRouter } from 'react-router';
import Profile from 'pages/Audiences/Profile';
import SendTestSidebar from 'components/molecules/SendTest/SendTestSidebar';
import { LibrarySidebar } from 'components/organisms/LibraryStack';
import { StatisticsSidebar, CreateCampaign } from 'components/organisms/CampaignStack';
import { useLocalStore } from 'store/index';


const ContextSidebar = ({ hideSidebar, history }) => {
    const [getContextSidebar] = useLocalStore((state) => [state.getContextSidebar]);

    const context = getContextSidebar();
    const { id } = useParams();

    console.log('setContextSidebar', context);

    // TODO: these should be rendered conditionally - otheriwse its rendering every single one, just not
    //  displaying them
    const contexts = {
        // Journeys
        'Journeys':  <JourneySidebar history={history} />,
        'CreateJourney': <CreateJourney />,
        'JourneyTrigger':  <TriggerSidebar flowId={id} hideSidebar={hideSidebar} />,
        'JourneyDropout': <DropoutSidebar isOpen={context.open} hideSidebar={hideSidebar} />,
        'JourneyReport': <JourneyReportSidebar status={context.element ?? false } />,
        'JourneyIssues': <JourneyIssuesSidebar issues={context.element ?? false } />,

        // MISC
        'DeliveryReport': <DeliveryReportSidebar />,
        'Library': <LibrarySidebar template={context.element ?? false } />,

        // messages / campaigns
        'Campaigns': <CampaignSidebar type={context.element?.type ?? false} step={context.element ?? false} />,
        'CreateCampaign': <CreateCampaign step={context.element ?? false} />,
        'SendTest': <SendTestSidebar message={context.element ?? false} />,

        // profiles
        'Customers': <Profile profileId={context?.props?.profileId} />

    };


    return (
        <>
            {contexts[context.context ?? false]}
        </>
    );
};

export default withRouter(ContextSidebar);
