import { createFromCall } from '../../util/Type';

const PREFIX = 'Segments/';

export const CALLS = {
    FETCH_SEGMENTS: `${PREFIX}FETCH_SEGMENTS_START`,
    CREATE_SEGMENTS: `${PREFIX}CREATE_SEGMENTS_START`,
    DELETE_SEGMENTS: `${PREFIX}DELETE_SEGMENTS_START`,
    EDIT_SEGMENTS: `${PREFIX}EDIT_SEGMENTS_START`,
    FETCH_SINGLE_SEGMENT: `${PREFIX}FETCH_SINGLE_SEGMENT_START`
};

export const FETCH_SEGMENTS_START = CALLS.FETCH_SEGMENTS;
export const FETCH_SEGMENTS_SUCCESS = createFromCall(CALLS.FETCH_SEGMENTS, 'SUCCESS');
export const FETCH_SEGMENTS_ERROR = createFromCall(CALLS.FETCH_SEGMENTS, 'ERROR');

export const CREATE_SEGMENTS_START = CALLS.CREATE_SEGMENTS;
export const CREATE_SEGMENTS_SUCCESS = createFromCall(CALLS.CREATE_SEGMENTS, 'SUCCESS');
export const CREATE_SEGMENTS_ERROR = createFromCall(CALLS.CREATE_SEGMENTS, 'ERROR');

export const DELETE_SEGMENTS_START = CALLS.DELETE_SEGMENTS;
export const DELETE_SEGMENTS_SUCCESS = createFromCall(CALLS.DELETE_SEGMENTS, 'SUCCESS');
export const DELETE_SEGMENTS_ERROR = createFromCall(CALLS.DELETE_SEGMENTS, 'ERROR');

export const EDIT_SEGMENTS_START = CALLS.EDIT_SEGMENTS;
export const EDIT_SEGMENTS_SUCCESS = createFromCall(CALLS.EDIT_SEGMENTS, 'SUCCESS');
export const EDIT_SEGMENTS_ERROR = createFromCall(CALLS.EDIT_SEGMENTS, 'ERROR');

export const FETCH_SINGLE_SEGMENT_START = CALLS.FETCH_SINGLE_SEGMENT;
export const FETCH_SINGLE_SEGMENT_SUCCESS = createFromCall(CALLS.FETCH_SINGLE_SEGMENT, 'SUCCESS');
export const FETCH_SINGLE_SEGMENT_ERROR = createFromCall(CALLS.FETCH_SINGLE_SEGMENT, 'ERROR');