import { DateTime } from 'luxon';
import { dateFormat, dateTimeFormat } from './constants';

export const formatDateTime = (date, format = dateTimeFormat) => {
    if (typeof date !== 'string' || date === '') return date;
    return DateTime.fromISO(date).toFormat(format);
};

export const formatDate = (date, format = dateFormat) => {
    if (typeof date !== 'string' || date === '') return date;
    return DateTime.fromISO(date).toFormat(format);
};

export const emailValidation = value => value && /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(value);

export const saveLocal = (itemName, payload) => {
    localStorage.setItem(itemName, JSON.stringify(payload));
};

export const getLocal = (itemName, id) => {
    if(id){
        const list = JSON.parse(localStorage.getItem(itemName));
        if(Array.isArray(list) && list.length > 1){
            return list.find(item => parseInt(item.id) === parseInt(id));
        }
        return list;
    }
    return JSON.parse(localStorage.getItem(itemName));
};

export const removeLocal = (itemName) => {
    localStorage.removeItem(itemName);
};

export const createCampaignTableData = (id, label, name, sends, opens, clicks, revenue) => {
    return {
        id,
        label,
        name,
        sends,
        opens,
        clicks,
        revenue
    };
};

export const createFlowsTableData = (id, label, name, description) => {
    return {
        id,
        name,
        label,
        description
    };
};

export const getComparator = (order, orderBy) => {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
};

export const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
};

export const getOrganization = () => {
    const organizationString = localStorage.getItem('organization');
    const organization = JSON.parse(organizationString);
    const org = organization ? organization : false;
    return org;
};
const getActive = localStorage.getItem('organization');
const orgId = getActive && JSON.parse(getActive).organization_id;

export const getSavedProps = async () =>
    await fetch(`${process.env.REACT_APP_API_URL}/api/v1/${orgId}/profile/properties`, {
        method: 'GET',
        headers: { Accept: 'application/json' },
        credentials: 'include',
        withCredentials: true
    })
        .then(response => response.json())
        .then(response => !response?.errors && localStorage.setItem('profile_props', JSON.stringify(response)))
        .catch(e => console.log(e));

orgId && getSavedProps();



export const handleInitialRedirect = () => {
    window.location.replace('/campaigns/draft');
};

export const checkRedirect = () => {
    const next = localStorage.getItem('next');
    if (next) {
        window.location.replace(next);
    }
};

export const checkShopifyRedirect = () => {
    const params = new URLSearchParams(window.location.search);
    const nextParams = params.get('next');

    if (params.has('next')) {
        window.location.replace(nextParams);
    } else {
        window.location.replace('/campaigns/draft');
    }
};

export const loadAppcues = (currentUser) => {
    const load = require('load-script');
    window.AppcuesSettings = {
        enableURLDetection: true
    };
    load('https://fast.appcues.com/115830.js', (err, script) => {
        if (err) {
            console.log(err);
        } else {
            window.Appcues.identify(currentUser.identity.id, { // Unique identifier for current user
                email: currentUser.identity.traits.email
            });
        }
    });
};

export const saveUrlParams = (search) => {
    const params = new URLSearchParams(search);
    const nextParams = params.get('next');
    const urlParam = nextParams?.split('shop_url=')[1];
    if (params.has('next')) localStorage.setItem('next', nextParams);
    if (urlParam) localStorage.setItem('shop_url', urlParam);
};

export const stringToReadable = (string, separator) => {
    return string.replaceAll(separator, ' ');
};

export const logger = (log) => {
    const isDevEnv = process.env.NODE_ENV === 'development';
    if(isDevEnv) {
        console.log(log);
    }
};





