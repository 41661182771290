import { createFromCall } from '../../util/Type';

const PREFIX = 'Identities/';

export const CALLS = {
    FETCH_IDENTITIES: `${PREFIX}FETCH_IDENTITIES_START`,
    POST_IDENTITIES: `${PREFIX}POST_IDENTITIES_START`
};

export const FETCH_IDENTITIES_START = CALLS.FETCH_IDENTITIES;
export const FETCH_IDENTITIES_SUCCESS = createFromCall(CALLS.FETCH_IDENTITIES, 'SUCCESS');
export const FETCH_IDENTITIES_ERROR = createFromCall(CALLS.FETCH_IDENTITIES, 'ERROR');

export const POST_IDENTITIES_START = CALLS.POST_IDENTITIES;
export const POST_IDENTITIES_SUCCESS = createFromCall(CALLS.POST_IDENTITIES, 'SUCCESS');
export const POST_IDENTITIES_ERROR = createFromCall(CALLS.POST_IDENTITIES, 'ERROR');
