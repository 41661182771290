import React from 'react';
import { Typography, Skeleton, Tooltip, ClickAwayListener, InputBase } from '@mui/material';
import { SmsTwoTone, EmailTwoTone, CallSplitRounded, TimelapseTwoTone } from '@mui/icons-material';
import { flowStatesHelper, flowSendStates } from 'constants/journeysConstants';
import { useNodesState } from 'reactflow';
import {useParams} from 'react-router-dom';
import { useLocalStore } from 'store/index';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { setStepLabel } from 'api/Journeys';

const StepHeader = ({ stepData, status, children, handleSidebar = false }) => {
    const queryClient = useQueryClient();
    const { id } = useParams();
    const [showName, setShowName] = React.useState(false);
    const [name, setName] = React.useState('');

    const [nodes, setNodes, onNodesChange] = useNodesState([]);

    const updateLabel = useMutation(setStepLabel, {
        onSuccess: () => {
            queryClient.invalidateQueries(['journey_step', stepData.id]);
        }
    });

    const [getEdit] = useLocalStore(
        (state) => [state.getEdit]
    );

    const editState = getEdit(id);

    React.useEffect(() => {
        stepData && setName(stepData?.message?.subject ?? stepData.label);

        if( stepData ){
            // active email
        }

    }, [stepData]);

    const icon = (type) => {
        switch(type){
        case 'email':
            return <EmailTwoTone fontSize='small' />;
        case 'sms':
            return <SmsTwoTone fontSize='small' />;
        case 'wait':
            return <TimelapseTwoTone fontSize='small' />;
        case 'branch_filter':
            return <CallSplitRounded fontSize='small' />;
        default:
            return '';
        }
    };

    const onChangeName = () => {
        setShowName((state) => !state);
    };

    const onUpdate = React.useCallback(() => {
        const update = async () => {
            await updateLabel.mutate({id, stepId: stepData.id, payload: {label: name}} );
        };

        if(stepData?.label !== name) {
            setNodes(nodes.filter(el => el.data.stepId !== stepData.id));
            update();
        }
        setShowName(false);
    }, [stepData, setShowName, nodes, name, id, setNodes, stepData]);

    const onChange = (event) => {
        setName(event.target.value);
    };

    const statusBadge = editState?.state && flowStatesHelper(status, stepData.has_changes, 'small');

    const canSend = stepData.has_changes && flowSendStates[stepData.can_activate];

    return (
        <>
            {stepData ?
                <div className='flow-node__header' onClick={(e) => handleSidebar && handleSidebar(e)}>
                    <Typography className='node-icon' style={{width: '100% !important'}}>
                        {icon(stepData.type)}
                    </Typography>
                    {!children ?
                        <ClickAwayListener onClickAway={onUpdate}>
                            {showName ? (
                                <InputBase id='campaign-subject-input' value={name} onChange={onChange} className='flow-header__title' style={{fontSize: 12, marginLeft: 7}} autoFocus/>
                            ) : (
                                <div onClick={onChangeName} style={{display: 'flex', alignItems: 'center'}}>
                                    <Tooltip placement='top-start' title={'Click to change'}>
                                        <Typography variant='caption' component='div' id='campaign-subject' style={{fontWeight: 400, marginLeft: 7, fontSize: 12, marginRight: 5}}>
                                            {name}
                                        </Typography>
                                    </Tooltip>
                                </div>
                            )}
                        </ClickAwayListener>
                        : children}
                    <Typography variant='caption' component='div' style={{display: 'inline-flex', alignItems: 'center', fontWeight: 500, marginLeft: 'auto', fontSize: 14, marginRight: 0, position: 'absolute', right: 0, background: 'white', paddingLeft: 5, paddingRight: 5}}>
                        {statusBadge}
                        {status === 'draft' && canSend}
                    </Typography>
                </div>
                :
                <div className='flow-node__header'>
                    <Typography className='node-icon' style={{width: '100% !important'}}>
                        <Skeleton variant='circular' animation='wave' width={15} height={15} />
                    </Typography>
                    <Typography variant='caption' component='div' style={{fontWeight: 400, marginLeft: 7, fontSize: 14, marginRight: 5}}>
                        <Skeleton animation='wave' width={150} variant='text' />
                    </Typography>
                    <Typography variant='caption' component='div' style={{fontWeight: 500, marginLeft: 'auto', fontSize: 14, marginRight: 0}}>
                        <Skeleton animation='wave' width={50} variant='text' />
                    </Typography>
                </div>
        }
        </>
    );
};

export default StepHeader;
