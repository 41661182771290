import React from 'react';
import { EnterNode, EmailNode, BranchNode, WaitNode, ExitNode, SMSNode} from '../components/molecules/Journeys/Nodes';
import { AppendEdge, MergeEdge } from '../components/molecules/Journeys/Edges';
import { Chip } from '@mui/material';
import { CallSplitRounded, QueryBuilderRounded, MailOutlineRounded, ErrorTwoTone, CheckCircleTwoTone, SmsTwoTone } from '@mui/icons-material';

export const layoutOptions = {
    'elk.layered.directionCongruency': 'READING_DIRECTION',
    'elk.partitioning.activate': true,
    'elk.hierarchyHandling': 'SEPARATE_CHILDREN',
    'elk.layered.nodePlacement.strategy': 'NETWORK_SIMPLEX',
    'elk.layered.nodePlacement.bk.edgeStraightening': 'IMPROVE_STRAIGHTNESS',
    'elk.layered.nodePlacement.favorStraightEdges': true,
    // 'elk.layered.compaction.postCompaction.strategy': 'EDGE_LENGTH',
    // 'elk.layered.nodePlacement.networkSimplex.nodeFlexibility.default': 'NODE_SIZE_WHERE_SPACE_PERMITS',
    'elk.layered.nodePlacement.bk.fixedAlignment': 'BALANCED',
    // Spacing
    // 'elk.spacing.nodeNode': 0,
    'elk.layered.spacing.nodeNodeBetweenLayers': 30,
    //'elk.spacing.componentComponent': 60,
    // 'elk.spacing': 110,
    // 'elk.spacing.individual': 150,
    'elk.spacing.edgeNode': 0,
    'elk.spacing.portPort': 10,

    'elk.layered.spacing.edgeEdge': 0,
    'elk.layered.spacing.edgeEdgeBetweenLayers': 0,

    //Edges
    // 'elk.layered.considerModelOrder.longEdgeStrategy': 'DUMMY_NODE_UNDER',

    // Debugging
    'elk.debugMode': true,

    'elk.zoomToFit': true,
    'elk.interactive': true,
    'elk.interactiveLayout': true,
    'elk.omitNodeMicroLayout': true,
    'elk.mergeEdges': true
};

export const EDGE_TYPES = {
    draft: {
        append: AppendEdge,
        merged: MergeEdge
    },
    active: {
        append: MergeEdge,
        merged: MergeEdge
    }
};

export const NODE_GROUPS = [
    {
        label: 'Email',
        icon: <MailOutlineRounded />,
        type: 'email'
    },
    {
        label: 'SMS',
        icon: <SmsTwoTone />,
        type: 'sms'
    },
    {
        label: 'Split',
        icon: <CallSplitRounded />,
        type: 'branch_filter'
    },
    {
        label: 'Wait',
        icon: <QueryBuilderRounded />,
        type: 'wait'
    }
];

export const NODE_TYPES = {
    enter: EnterNode,
    branch_filter: BranchNode,
    wait: WaitNode,
    exit: ExitNode,
    email: EmailNode,
    sms: SMSNode
    //merge: MergeNode,
};

// export const NODE_RTYPES = {
//     enter: EnterNodeR,
//     branch_filter: BranchNodeR,
//     wait: WaitNodeR,
//     exit: ExitNodeR,
//     email: EmailNodeR
//     //sms: SMSNode
// };

export const NODE_SIDEBARS = [
    'branch_filter',
    'wait',
    'email',
    'sms'
];

export const NODE_HIDDEN = [
    'enter',
    'exit'
];

export const STEP_TYPES = Object.entries(NODE_TYPES).map(([type, node]) => type);

export const NODE_EDGE = {
    enter: 'append',
    wait: 'append',
    branch_filter: 'append',
    wait_internal: 'regular',
    sms: 'append',
    email: 'append',
    merge: 'merged',
    exit: 'append'
};

export const NODE_WIDTH = 250;
export const NODE_HEIGHT = 70;


export const NODE_EXTENT = [
    [0,0],
    [2500, 2500]
];

export const flowStates = {
    'edited': <Chip size='small' label='Edited' color='secondary' className='flow-header__status secondary' style={{fontSize: 12, padding: '0px !importantx', fontWeight: 400}}/>,
    'draft': <Chip size='small' label='Draft' color='secondary' className='flow-header__status default' style={{fontSize: 12, padding: '0px !importantx', fontWeight: 400}}/>,
    'active': <Chip size='small' label='Active' color='primary' className='flow-header__status primary' style={{fontSize: 12, padding: '0px !importantx', fontWeight: 400}}/>
};

export const flowStatesHelper = (status, isChanged, size = 'small') => {
    let fontSize = 14;
    if(size === 'small') {
        fontSize = 10;
    } else if(size === 'normal') {
        fontSize = 18;
    }

    const states = {
        'edited': <Chip size={size} label='Edited' color='secondary' className='flow-header__status secondary' style={{fontSize: fontSize, padding: '0px !importantx', fontWeight: 400}}/>,
        'draft': <Chip size={size} label='Draft' color='secondary' className='flow-header__status default' style={{fontSize: fontSize, padding: '0px !importantx', fontWeight: 400}}/>,
        'active': <Chip size={size} label='Active' color='primary' className='flow-header__status primary' style={{fontSize: fontSize, padding: '0px !importantx', fontWeight: 400}}/>
    };

    let state = status;

    if(status === 'active' && isChanged) {
        state = 'edited';
    }

    return states[state];
};

export const flowSendStates = {
    false: <ErrorTwoTone color='warning' style={{fontSize: 19}} />,
    true: <CheckCircleTwoTone color='success' style={{fontSize: 19}} />
};

export const FLOWS_TABLE_HEADERS =  [
    {
        id: 'i',
        numeric: true,
        disablePadding: true,
        label: ''
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Name'
    },
    {
        id: 'label',
        numeric: false,
        disablePadding: false,
        label: 'Label'
    },
    {
        id: 'description',
        numeric: false,
        disablePadding: false,
        label: 'Description'
    }
];

// export const MESSAGE_STEPS = [
//     'headers', 'content', 'send-preview'
// ];

export const SMS_MESSAGE_STEPS = [
    'message', 'send-preview'
];

export const ISSUE_SECTIONS = {
    'filter': 'Branch',
    'allocations': 'Message',
    'wait': 'Wait'
};
