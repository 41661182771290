import React, {useContext, useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MessageReportSidebar, WaitReportSidebar, BranchReportSidebar } from 'components/molecules/Journeys/ReportSidebar';
import { hideContext } from 'redux/Context/context_actions';
import { Box, Typography } from '@mui/material';
import { EmailTwoTone, CallSplitRounded, TimelapseTwoTone } from '@mui/icons-material';
import {useParams} from 'react-router-dom';
import Alert from '@mui/lab/Alert';

const JourneyReportSidebar = ({ history, status }) => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const step = useSelector((state) => state.Context.data.element);

    const [ label, setLabel ] = useState(false);
    const [ singleFlowStep, setSingleFlowStep ] = useState({});
    // const { getFlowById, getStepById, singleFlowStep, neutralState, setNeutralState } =  useContext(FlowsContext);
    const stepId = step.stepId;
    const [ isLoading, setIsLoading ] = useState(true);

    const getFlowById = async (id) => {

    };

    const getStepById = async (id) => {

    };

    useEffect(() => {
        const getItems = async () => {
            await getFlowById(id);
            if(stepId) await getStepById(id, stepId);
            setIsLoading(false);
        };

        getItems();
    }, []);

    useEffect(() => {
        singleFlowStep && setLabel(singleFlowStep.label);
    }, [singleFlowStep]);


    const hideSidebar = async () => {
        await dispatch(hideContext());
        // setNeutralState({...neutralState, fetched: true, fetching: false, error: null});
    };

    const onCreateMessage = () => {
        dispatch(hideContext());
        history.push(`/journeys/${id}/message/${stepId}/headers`);
    };

    const sidebars = {
        'branch_filter': [<CallSplitRounded color='success' />, <BranchReportSidebar stepId={step.stepId} keyFrom={step.follow_key_from} hideSidebar={hideSidebar} />],
        'wait': [<TimelapseTwoTone color='warning' />, <WaitReportSidebar stepId={step.stepId} keyFrom={step.follow_key_from} hideSidebar={hideSidebar} />],
        'email': [<EmailTwoTone color='secondary'/>, <MessageReportSidebar tab={step.activeTab} stepId={step.stepId} keyFrom={step.follow_key_from} hideSidebar={hideSidebar} onCreateMessage={onCreateMessage} />]
    };

    return (
        <Box className='flow-sidebar'>
            <Box className='flow-sidebar__header'>
                <Typography component='div' variant='subtitle1' className='flow-sidebar_title' style={{border: 0, cursor: 'regular'}}>
                    {label} Stats
                </Typography>
                {/* <CompareArrowsTwoTone /> */}
            </Box>
            {/*{(neutralState.error?.errors || neutralState.error) &&*/}
            {/*<Box mt={4} mr={2} ml={2}>*/}
            {/*    <Alert severity='warning'>Please check your conditions again</Alert>*/}
            {/*</Box>}*/}
            {!isLoading && sidebars[step.type][1]}
        </Box>
    );
};

export default JourneyReportSidebar;
