import {getOrganization} from 'util/helper';
const orgId = getOrganization().organization_id ?? getOrganization().id;

const apiUrl = `${process.env.REACT_APP_API_URL}`;

export const apiV1OrgUri = `${apiUrl}/api/v1/${orgId}`;

// BEE Auth
export const API_BEE_ROUTE = `${apiV1OrgUri}/bee_backend_authenticate`;

export const API_BEE_CONTENT_EXTENSIONS_ROUTE = `${apiV1OrgUri}/bee_content_extensions`;

//Identities
export const API_IDENTITIES_ROUTE = `${apiUrl}/api/v1/identities`;

// Message Stats
// export const API_MESSAGE_STATS_ROUTE = `${apiV1OrgUri}/stats/messages/`;

//Static segments
export const API_SEGMENTS_ROUTE = `${apiV1OrgUri}/segments`;


//Profiles
//export const API_PROFILES_ROUTE = `${apiV1OrgUri}/profiles`;
export const API_SINGLE_PROFILE_ROUTE = `${apiV1OrgUri}/profiles/%(id)s`; //get / delete

export const API_POST_PROFILES_ROUTE = `${apiV1OrgUri}/profiles/campaign/addupdate`; //add new list segment
export const API_DELETE_PROFILES_ROUTE = `${apiV1OrgUri}/profiles/campaign/delete`;
export const API_SUPPRESS_PROFILES_ROUTE = `${apiV1OrgUri}/profiles/campaign/suppress`;

//Profile props
export const API_GET_PROFILE_PROPERTIES_ROUTE = `${apiV1OrgUri}/profile/properties`;
export const API_SINGLE_PROFILE_PROPERTY_ROUTE = `${apiV1OrgUri}/profile/properties/%(id)s`; //edit/delete

export const API_GET_SINGLE_PROFILE_LOGS_ROUTE = `${apiV1OrgUri}/profiles/%(id)s/logs`;
export const API_GET_SINGLE_PROFILE_ACTIVITY_ROUTE = `${apiV1OrgUri}/profiles/%(id)s/activities`;

//Statistics
export const API_CAMPAIGN_STATISTICS_ROUTE = `${apiV1OrgUri}/messages/stats/message/%(id)s`;
export const API_VARIANT_STATISTICS_ROUTE = `${apiV1OrgUri}/messages/%(id)s/stats/variant/%(variantId)s`;

//Variants
export const API_VARIANT_STRATEGY_ROUTE = `${apiV1OrgUri}/messages/campaign/%(id)s/strategy`;

//Flows
export const API_FLOWS_ROUTE = `${apiV1OrgUri}/flows`;



export const API_BRANCH_FILTER = `${apiV1OrgUri}/flows/%(id)s/steps/branch_filter/%(stepId)s/filter`;
export const API_SINGLE_BRANCH_FILTER = `${apiV1OrgUri}/flows/%(id)s/steps/%(stepId)s`;
export const API_BYPASS_FILTER = `${apiV1OrgUri}/flows/%(id)s/steps/common/%(stepId)s/bypass_filter`;
export const API_SINGLE_FLOW_STEP = `${apiV1OrgUri}/flows/%(id)s/steps/%(stepId)s`;
export const API_WAIT_FLOW_STEP = `${apiV1OrgUri}/flows/%(id)s/steps/common/%(stepId)s/wait`;

//Settings
export const API_SETTINGS_ROUTE = `${apiV1OrgUri}/settings`;
export const API_SETTINGS_NAME_ROUTE = `${apiV1OrgUri}/settings-name`;


//Shopify
export const API_SHOPIFY_INTEGRATE_ROUTE = `${apiV1OrgUri}/shopify/integrate`;
export const API_SHOPIFY_DISABLE_ROUTE = `${apiV1OrgUri}/shopify/disable`;
export const API_SHOPIFY_STATUS = `${apiV1OrgUri}/shopify/status`;
export const API_SHOPIFY_TIERS = `${apiV1OrgUri}/shopify/tiers`;
export const API_SHOPIFY_START_BILLING = `${apiV1OrgUri}/shopify/billing/start`;
export const API_SHOPIFY_SUB_STATUS = `${apiV1OrgUri}/shopify/subscription/status`;
export const API_SHOPIFY_SUB_CANCEL = `${apiV1OrgUri}/shopify/billing/cancel`;


//Batches
export const API_BATCHES_ROUTE = `${apiV1OrgUri}/batches/messages`;
