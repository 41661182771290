import { withType } from '../../util/util';
import { SHOW_CONTEXT_START, SHOW_CONTEXT_SUCCESS, SHOW_CONTEXT_ERROR } from './context_types';

export const hideContext = () => async (dispatch) => {
    try {
        const data = {
            open: false,
            sidebar: false,
            element: false
        };

        dispatch(withType(SHOW_CONTEXT_START));
        dispatch(withType(SHOW_CONTEXT_SUCCESS, { data }));
    } catch (error) {
        console.error(error);
        dispatch(withType(SHOW_CONTEXT_ERROR, { error }));
    }
};
