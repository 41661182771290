import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Divider, Typography } from '@mui/material';
import { parseIssues } from 'util/helpers/journeysHelper';
import { Issue } from 'components/atoms';
import { useJourney } from 'api/Journeys/journey_queries';
import { useLocalStore } from 'store/index';

import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';

const JourneyIssuesSidebar = () => {
    const { id } = useParams();

    const { status, data: journey, error, isFetching } = useJourney(id);

    const [ triggerIssue, setTriggerIssue ] = useState(false);
    const [ dropoutFilter, setDropoutFilter ] = useState(false);
    const [ stepIssues, setStepIssues ] = useState(false);

    const [setContextSidebar, resetContextSidebar] = useLocalStore(
        (state) => [state.setContextSidebar, state.resetContextSidebar]
    );

    useEffect(() => {
        if(journey) {
            const { activation_issues } = journey;
            sectionIssue(activation_issues);
        }
    }, [journey]);

    const sectionIssue = (issues) => {
        if(!issues.is_valid_section) {
            if(issues.issue_codes.includes('trigger_empty')) {
                setTriggerIssue(true);
            }
        }

        if(issues.sub_sections.find(issue => issue.section === 'dropout_filter')){
            setDropoutFilter(true);
        }

        const issuesParsed = issues.sub_sections.filter(item => item.issues.find(issue => !issue.is_valid));
        const stepIssues = issuesParsed.length > 0 && issuesParsed[0].issues.filter(item => !item.is_valid);

        const list = [];
        Object.entries(stepIssues).map(issue => list.push(parseIssues(issue[1])));
        setStepIssues(list);
    };

    const onTrigger = () => {
        const payload = {
            open: true,
            context: 'JourneyTrigger',
            element: {
                flowId: id
            }
        };

        setContextSidebar(payload);
    };

    const onDropout = () => {
        const payload = {
            open: true,
            context: 'JourneyDropout',
            element: {
                flowId: id
            }
        };

        setContextSidebar(payload);
    };

    return (
        <Box className='flow-sidebar'>
            <Box className='flow-sidebar__header'>
                <Typography component='div' variant='subtitle1' className='flow-sidebar_title' style={{border: 0}}>
                    <ErrorTwoToneIcon />
                    Activation Issues
                </Typography>
            </Box>
            <Box className='flow-sidebar__body' sx={{px: 2}}>
                {(triggerIssue || dropoutFilter) && <Divider sx={{mt: 2, mb: 2}} textAlign='left'>Setup Issues</Divider>}
                {triggerIssue &&
                    <Issue issue={triggerIssue} title='Start Journey'>
                        Person start the journey not configured — <strong onClick={onTrigger} style={{cursor: 'pointer'}}>check it out!</strong>
                    </Issue>
                }
                {dropoutFilter &&
                    <Issue issue={dropoutFilter} type='info' title='Finish Journey'>
                        Person ends the journey not configured — <strong onClick={onDropout} style={{cursor: 'pointer'}}>check it out!</strong>
                    </Issue>
                }
                {stepIssues?.length > 0 &&
                    <>
                        <Divider sx={{mt: 2, mb: 2}} textAlign='left'>Jorney Issues</Divider>
                        {stepIssues.map((issue) => {
                            return <Issue key={issue[0]} issue={issue} />;
                        })}
                    </>
               }
            </Box>
        </Box>
    );
};

export default JourneyIssuesSidebar;
