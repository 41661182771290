import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, IconButton, Typography } from '@mui/material';
import { LocalAtmTwoTone, CallSplitRounded, MessageTwoTone, TimelapseTwoTone, ExpandMore, ExpandLess } from '@mui/icons-material';
import { useJourneyStepStats } from 'api/Journeys/journey_queries';
import { SidebarGroup } from 'components/atoms';
import StatsJourneyStepMessageEmail from 'components/molecules/Journeys/Sidebar/Stats/StatsJourneyStepMessageEmail';

const MessageSidebar = ({ stepId }) => {
    const { id } = useParams();

    // TODO? move the query into the stats component itself?
    const { status, data, error, isFetching } = useJourneyStepStats(id, stepId);

    return (
        <Box className='flow-sidebar__body' p={0}>
            <Box className='flow-sidebar__body-content'>
                {status === 'loading' ? (
                    'Loading...'
                ) : status === 'error' ? (
                    <span>Error: {error.message}</span>
                ) : (
                    <>{isFetching ? 'Background Updating...' : <StatsJourneyStepMessageEmail stats={data} />}</>
                )}
            </Box>
        </Box>
    );
};

export default MessageSidebar;
