import React, { useCallback } from 'react';
import ReactFlow, { useNodes, useNodesState, useStore, useReactFlow } from 'reactflow';
import { Alert, AlertTitle } from '@mui/material';

const Issue = ({ issue, title, children, type = 'warning' }) => {
    const { setCenter } = useReactFlow();
    const setSelectedElements = useStore((actions) => actions.setSelectedElements);

    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const nodesSet = useNodes();

    const focusStep = useCallback(
        (stepId) => {
            const step = nodesSet.find(node => node.id === `step-${stepId}`);
            setNodes(nodesSet.map(node => {
                if(node.id === `step-${stepId}`){
                    node.selected = true;
                }
                return node;
            }));
            const { x, y } = step.position;
            setCenter(x+200, y+100, 1.25);
        },
        [setCenter, nodesSet, setSelectedElements]
    );

    {/* Variant Can Not Be Made Sendable  */}
    {/* <Alert severity='warning' sx={{mb: 1}}>
                    <AlertTitle>Email #3</AlertTitle>
                    Subject is missing — <strong>check it out!</strong>
                    <br/>
                    Missing sender information — <strong>check it out!</strong>
                </Alert> */}

    const body = !children ? <>{issue[2]} - <strong onClick={() => focusStep(issue[0])} style={{cursor: 'pointer'}}>check it out!</strong></> : children;

    return (
        <Alert severity={type} sx={{mb: 1}}>
            <AlertTitle>{title ? title : issue[3]}</AlertTitle>
            {body}
        </Alert>
    );
};

export default Issue;
