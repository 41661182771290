import { createFromCall } from '../../util/Type';

const PREFIX = 'Profiles/';

export const CALLS = {
    FETCH_PROFILES_SEGMENT: `${PREFIX}FETCH_PROFILES_SEGMENT_START`,
    POST_PROFILES_SEGMENT: `${PREFIX}POST_PROFILES_SEGMENT_START`,
    DELETE_PROFILES_SEGMENT: `${PREFIX}DELETE_PROFILES_SEGMENT_START`,
    FETCH_PROFILES: `${PREFIX}FETCH_PROFILES_START`,
    POST_PROFILES: `${PREFIX}POST_PROFILES_START`,
    DELETE_PROFILES: `${PREFIX}DELETE_PROFILES_START`,
    SUPPRESS_PROFILES: `${PREFIX}SUPPRESS_PROFILES_START`,
    FETCH_SINGLE_PROFILE: `${PREFIX}FETCH_SINGLE_PROFILE_START`,
    DELETE_SINGLE_PROFILE: `${PREFIX}DELETE_SINGLE_PROFILE_START`,
    FETCH_PROFILE_PROPERTIES: `${PREFIX}FETCH_PROFILE_PROPERTIES`
};

export const FETCH_PROFILES_SEGMENT_START = CALLS.FETCH_PROFILES_SEGMENT;
export const FETCH_PROFILES_SEGMENT_SUCCESS = createFromCall(CALLS.FETCH_PROFILES_SEGMENT, 'SUCCESS');
export const FETCH_PROFILES_SEGMENT_ERROR = createFromCall(CALLS.FETCH_PROFILES_SEGMENT, 'ERROR');

export const POST_PROFILES_SEGMENT_START = CALLS.POST_PROFILES_SEGMENT;
export const POST_PROFILES_SEGMENT_SUCCESS = createFromCall(CALLS.POST_PROFILES_SEGMENT, 'SUCCESS');
export const POST_PROFILES_SEGMENT_ERROR = createFromCall(CALLS.POST_PROFILES_SEGMENT, 'ERROR');

export const DELETE_PROFILES_SEGMENT_START = CALLS.DELETE_PROFILES_SEGMENT;
export const DELETE_PROFILES_SEGMENT_SUCCESS = createFromCall(CALLS.DELETE_PROFILES_SEGMENT, 'SUCCESS');
export const DELETE_PROFILES_SEGMENT_ERROR = createFromCall(CALLS.DELETE_PROFILES_SEGMENT, 'ERROR');

export const FETCH_PROFILES_START = CALLS.FETCH_PROFILES;
export const FETCH_PROFILES_SUCCESS = createFromCall(CALLS.FETCH_PROFILES, 'SUCCESS');
export const FETCH_PROFILES_ERROR = createFromCall(CALLS.FETCH_PROFILES, 'ERROR');

export const POST_PROFILES_START = CALLS.POST_PROFILES;
export const POST_PROFILES_SUCCESS = createFromCall(CALLS.POST_PROFILES, 'SUCCESS');
export const POST_PROFILES_ERROR = createFromCall(CALLS.POST_PROFILES, 'ERROR');

export const DELETE_PROFILES_START = CALLS.DELETE_PROFILES;
export const DELETE_PROFILES_SUCCESS = createFromCall(CALLS.DELETE_PROFILES, 'SUCCESS');
export const DELETE_PROFILES_ERROR = createFromCall(CALLS.DELETE_PROFILES, 'ERROR');

export const SUPPRESS_PROFILES_START = CALLS.SUPPRESS_PROFILES;
export const SUPPRESS_PROFILES_SUCCESS = createFromCall(CALLS.SUPPRESS_PROFILES, 'SUCCESS');
export const SUPPRESS_PROFILES_ERROR = createFromCall(CALLS.SUPPRESS_PROFILES, 'ERROR');

export const FETCH_SINGLE_PROFILE_START = CALLS.FETCH_SINGLE_PROFILE;
export const FETCH_SINGLE_PROFILE_SUCCESS = createFromCall(CALLS.FETCH_SINGLE_PROFILE, 'SUCCESS');
export const FETCH_SINGLE_PROFILE_ERROR = createFromCall(CALLS.FETCH_SINGLE_PROFILE, 'ERROR');

export const DELETE_SINGLE_PROFILE_START = CALLS.DELETE_SINGLE_PROFILE;
export const DELETE_SINGLE_PROFILE_SUCCESS = createFromCall(CALLS.DELETE_SINGLE_PROFILE, 'SUCCESS');
export const DELETE_SINGLE_PROFILE_ERROR = createFromCall(CALLS.DELETE_SINGLE_PROFILE, 'ERROR');
