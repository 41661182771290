import { createFromCall } from '../../util/Type';

const PREFIX = 'Bee/';

export const CALLS = {
    FETCH_BEE: `${PREFIX}FETCH_BEE_START`,
    FETCH_MERGE_TAGS: `${PREFIX}FETCH_MERGE_TAGS`,
    FETCH_DEFAULT_DISPLAY_CONDITIONS: `${PREFIX}FETCH_DEFAULT_DISPLAY_CONDITIONS`
};

export const FETCH_BEE_START = CALLS.FETCH_BEE;
export const FETCH_BEE_SUCCESS = createFromCall(CALLS.FETCH_BEE, 'SUCCESS');
export const FETCH_BEE_ERROR = createFromCall(CALLS.FETCH_BEE, 'ERROR');

export const FETCH_MERGE_TAGS_START = CALLS.FETCH_MERGE_TAGS;
export const FETCH_MERGE_TAGS_SUCCESS = createFromCall(CALLS.FETCH_MERGE_TAGS, 'SUCCESS');
export const FETCH_MERGE_TAGS_ERROR = createFromCall(CALLS.FETCH_MERGE_TAGS, 'ERROR');

export const FETCH_DEFAULT_DISPLAY_CONDITIONS_START = CALLS.FETCH_DEFAULT_DISPLAY_CONDITIONS;
export const FETCH_DEFAULT_DISPLAY_CONDITIONS_SUCCESS = createFromCall(CALLS.FETCH_DEFAULT_DISPLAY_CONDITIONS, 'SUCCESS');
export const FETCH_DEFAULT_DISPLAY_CONDITIONS_ERROR = createFromCall(CALLS.FETCH_DEFAULT_DISPLAY_CONDITIONS, 'ERROR');
