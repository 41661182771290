import React, { useContext, useState, useEffect } from 'react';
import { API_SETTINGS_ROUTE } from 'constants/apiRoutes';
import { useDispatch } from 'react-redux';
import { appendIdentity } from 'redux/Identities/identities_actions';
import {checkShopifyRedirect, handleInitialRedirect, loadAppcues, loadAppCues} from '../util/helper';
import { createOrganizationSettingsForm } from 'redux/api/organizationsController';
import {Settings} from 'luxon';

const AuthContext = React.createContext();
const kratosPublicUrl = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_KRATOS_PATH}`;
const apiUrl = `${process.env.REACT_APP_API_URL}`;
const appUrl = `${process.env.REACT_APP_BASE_URL}`;

export function useAuth () {
    return useContext(AuthContext);
}

export function AuthProvider ({ children }) {
    const [currentUser, setCurrentUser] = useState();
    const [currentForm, setCurrentForm] = useState();
    const [organizations, setOrganizations] = useState();
    const [loading, setLoading] = useState(true);
    const [authError, setAuthError] = useState(false);
    const [settingsData, setSettingsData] = useState();
    const [requestMessage, setRequestMessage] = useState();
    const [successMessage, setSuccessMessage] = useState(false);
    const [userSettings, setUserSettings] = useState();

    // useEffect(() => {
    //     if(currentUser) loadAppcues(currentUser);
    // }, [currentUser]);

    const handleError = async (response) => {
        setAuthError({
            statusText: response.statusText,
            status: response.status
        });
    };

    const dispatch = useDispatch();

    //Registration
    const getFlowIdRegister = async () => {
        await fetch(`${kratosPublicUrl}/self-service/registration/browser`, {
            method: 'GET',
            headers: { Accept: 'application/json' },
            credentials: 'include',
            withCredentials: true
        })
            .then(response => (response.json()))
            .then(response => setCurrentForm(response))
            .catch((error) => {
                handleError(200, error);
            });
    };

    const postRegistrationForm = async (flowId, data, history) => {
        const email = data.traits.email;
        await fetch(`${kratosPublicUrl}/self-service/registration?flow=${flowId}`, {
            method: 'POST',
            headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
            credentials: 'include',
            withCredentials: true,
            body: JSON.stringify(data)
        })
            .then(response => response.ok ? window.location.replace('/create-organization')
                : response.json().then(response => setCurrentForm(response)))
            .catch((error) => {
                handleError(200, error);
            });
    };

    const redirectToVerification = async (history) => {
        await fetch(`${kratosPublicUrl}/self-service/verification/browser`, {
            method: 'GET',
            headers: { Accept: 'application/json' },
            credentials: 'include',
            withCredentials: true
        })
            .then(window.location.replace('/verification'));
    };

    //Login
    const getFlowIdLogin = async () => {
        await fetch(`${kratosPublicUrl}/self-service/login/browser`, {
            method: 'GET',
            headers: { Accept: 'application/json' },
            credentials: 'include',
            withCredentials: true
        })
            .then(response => response.ok ? response.json() : response)
            .then(response => setCurrentForm(response))
            .catch((error) => { handleError(200, error); });
    };

    const postLoginForm = async (flowId, data, history) => {
        await fetch(`${kratosPublicUrl}/self-service/login?flow=${flowId}`, {
            method: 'POST',
            headers: { Accept: 'application/json', 'Content-Type': 'application/json'},
            credentials: 'include',
            withCredentials: true,
            body: JSON.stringify(data)
        })
            .then(response => response.ok ? redirectToOrgUrl(history) : response.json())
            .then(response => setCurrentForm(response))
            .catch((error) => { handleError(200, error); });
    };

    const setOrg = (json) => {
        const id = JSON.parse(JSON.stringify(json));
        localStorage.setItem('organization', JSON.stringify({'organization_id': id[0].id}));
        setOrganizations(json);
        localStorage.getItem('organization') && checkShopifyRedirect();
    };

    const redirectToOrgUrl = async () => {
        await fetch(`${kratosPublicUrl}/sessions/whoami`, { credentials: 'include' })
            .then(response => response.json())
            .then(response => {
                setCurrentUser(response);
                fetch(`${apiUrl}/api/v1/identities/current/organizations`,
                    {headers: { Accept: 'application/json' },
                        credentials: 'include',
                        withCredentials: true })
                    .then(res => res.json())
                    .then(res => res.length > 0 && setOrg(res));
            })
            .catch((error) => {  handleError(200, error); });
    };

    //Logout
    const logoutAction = async (history) => {
        await fetch(`${kratosPublicUrl}/self-service/logout/browser`, {
            method: 'GET',
            headers: { Accept: 'application/json' },
            credentials: 'include',
            withCredentials: true
        })
            .then(response => response.json())
            .then(response => {
                setCurrentForm(null);
                logoutUser(response.logout_url, history);
            })
            .catch((error) => { handleError(200, error); });
    };

    const redirectToLoginUrl = async () => {
        await checkIsUserLoggedIn();
        localStorage.removeItem('organization');
        localStorage.removeItem('profile_props');
    };

    const logoutUser = async (logoutURL) => {
        await fetch(logoutURL, {
            method: 'GET',
            headers: { Accept: 'application/json' },
            credentials: 'include',
            withCredentials: true
        })
            .then(response => response.ok ? redirectToLoginUrl() : response)
            .catch((error) => { handleError(200, error); });
    };

    //Settings
    const getSettingsFlowId = async () => {
        await fetch(`${kratosPublicUrl}/self-service/settings/browser`, {
            method: 'GET',
            headers: { Accept: 'application/json' },
            credentials: 'include',
            withCredentials: true
        })
            .then(response => response.json())
            .then(response => setCurrentForm(response))
            .catch((error) => { handleError(200, error); });
    };


    const updateUserSettings = async (flowId, data, history) => {
        await fetch(`${kratosPublicUrl}/self-service/settings?flow=${flowId}`, {
            method: 'POST',
            headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
            credentials: 'include',
            withCredentials: true,
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(response => setUserSettings(response))
            .catch((error) => setUserSettings(error));
    };

    //Recovery
    const getRecoveryFlowId = async () => {
        await fetch(`${kratosPublicUrl}/self-service/recovery/browser`, {
            method: 'GET',
            headers: { Accept: 'application/json' },
            credentials: 'include',
            withCredentials: true
        })
            .then(response => response.json())
            .then(response => setCurrentForm(response))
            .catch((error) => {  handleError(200, error); });
    };


    const sendRecoveryEmail = async (flowId, data) => {
        await fetch(`${kratosPublicUrl}/self-service/recovery?flow=${flowId}`, {
            method: 'POST',
            headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
            credentials: 'include',
            withCredentials: true,
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(response => setCurrentForm(response))
            .catch((error) => { handleError(200, error); });
    };


    //Verification
    const getVerificationFlowId = async () => {
        await fetch(`${kratosPublicUrl}/self-service/verification/browser`, {
            method: 'GET',
            headers: { Accept: 'application/json' },
            credentials: 'include',
            withCredentials: true
        })
            .then(response => response.json())
            .then(response => setCurrentForm(response))
            .catch((error) => { handleError(200, error); });

    };

    const inviteUser = async (data) => {
        await fetch(`${kratosPublicUrl}/identities`, {
            method: 'POST',
            credentials: 'include',
            withCredentials: true,
            headers: { Accept: 'application/json' },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(json => {
                sendInviteEmail(json.id);
                dispatch(appendIdentity({
                    identity_id: json.id
                }));
            })
            .catch((error) => {
                handleError(200, error);
            });
    };

    const sendInviteEmail = async (id) => {
        const data = {
            expires_in: '12h',
            identity_id: id
        };

        await fetch(`${kratosPublicUrl}/recovery/link`, {
            method: 'POST',
            headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
            withCredentials: true,
            credentials: 'include'
        })
            .then(response => response.json())
            .catch((error) => { handleError(200, error); });
    };

    const getProfileProps = async (res) => {
        await fetch(`/api/v1/${res?.[0]?.id}/profile/properties`, {
            method: 'GET',
            headers: { Accept: 'application/json' },
            credentials: 'include',
            withCredentials: true
        })
            .then(response => response.json())
            .then(response => {!response?.errors && localStorage.setItem('profile_props', JSON.stringify(response));
            });
    };

    const sendVerificationEmail = async (flowId, data) => {
        await fetch(`${kratosPublicUrl}/self-service/verification?flow=${flowId}`, {
            method: 'POST',
            headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
            credentials: 'include',
            withCredentials: true,
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .catch((error) => {handleError(200, error);});
    };

    const setOrgRefresh = async (res) => {
        await setOrganizations(res);
        const getLocalOrg = localStorage.getItem('organization');
        if(getLocalOrg === '{}' || !getLocalOrg){
            localStorage.setItem('organization', JSON.stringify({'organization_id': res?.[0]?.id}));
        }
        if(!getLocalOrg && currentUser?.identity?.verifiable_addresses?.[0].verified){
            window.location.reload();
        }
        if(!localStorage.getItem('profile_props') && getLocalOrg?.organization_id){
            getProfileProps(res);
        }
    };

    const fetchSettingsData = async () => {
        await fetch(API_SETTINGS_ROUTE, {
            method: 'GET',
            headers: { Accept: 'application/json' },
            credentials: 'include',
            withCredentials: true
        })
            .then(response => response.json())
            .then(response => {
                setSettingsData(response);
                const settingsZone = response?.setting_values?.timezone?.value;
                localStorage.setItem('default_timezone', JSON.stringify((settingsZone && settingsZone.length > 0) ? settingsZone : 'system'));
                Settings.defaultZone = (settingsZone && settingsZone.length > 0) ? settingsZone : 'system';
            });
    };

    const postOrganizationSettingsForm = async (data) => {
        try {
            const res = await createOrganizationSettingsForm(data);
            setRequestMessage(res);
            setSuccessMessage(true);
            fetchSettingsData();
            return res;
        } catch(error) {
            setRequestMessage(error);
            console.log(error);
            setSuccessMessage(false);

        }
    };

    const checkIsUserLoggedIn = async () => {
        await fetch(`${kratosPublicUrl}/sessions/whoami`, { credentials: 'include' })
            .then(response => response.ok ? response.json() : localStorage.clear())
            .then(response => {
                setCurrentUser(response);
                if(response.active) fetch(`${apiUrl}/api/v1/identities/current/organizations`,
                    {headers: { Accept: 'application/json' },
                        credentials: 'include',
                        withCredentials: true })
                    .then(res => res.ok ? res.json() : res)
                    .then(res => { setOrgRefresh(res); });
            })
            .catch((error) => { handleError(200, error); });
        setLoading(false);
    };

    useEffect(() => {
        checkIsUserLoggedIn();
    }, []);

    const value = {
        currentUser,
        currentForm,
        organizations,
        settingsData,
        requestMessage,
        userSettings,
        successMessage,
        postRegistrationForm,
        getFlowIdRegister,
        getFlowIdLogin,
        postLoginForm,
        logoutAction,
        getSettingsFlowId,
        updateUserSettings,
        getRecoveryFlowId,
        sendRecoveryEmail,
        getVerificationFlowId,
        inviteUser,
        sendInviteEmail,
        fetchSettingsData,
        sendVerificationEmail,
        postOrganizationSettingsForm,
        setRequestMessage,
        setSuccessMessage,
        setUserSettings
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
}
