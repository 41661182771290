import { initialState, neutralState } from '../helpers/defaultStateHelpers';
import { FETCH_STATS_START, FETCH_STATS_SUCCESS, FETCH_STATS_ERROR } from './campaign_statistics_types';

const fetchMessagesReducer = (state = { ...initialState }, action) => {
    switch (action.type) {
    case FETCH_STATS_START:
        return { ...state, fetching: true };
    case FETCH_STATS_SUCCESS:
        return { ...state, ...neutralState, ready: true, fetched: true, data: action.data || [] };
    case FETCH_STATS_ERROR:
        return { ...state, ...neutralState, error: action.error };
    default:
        return state;
    }
};

export default fetchMessagesReducer;