import { get } from 'lodash';
import { withType } from '../../util/util';
import { identitiesController } from '../api/index';
import { FETCH_IDENTITIES_ERROR, FETCH_IDENTITIES_START, FETCH_IDENTITIES_SUCCESS, POST_IDENTITIES_START, POST_IDENTITIES_SUCCESS, POST_IDENTITIES_ERROR } from './identities_types';

export const fetchIdentities = () => async (dispatch) => {
    try {
        dispatch(withType(FETCH_IDENTITIES_START));
        const res = await identitiesController.getIdentities();
        const data = get(res, 'identity_ids', null);
        dispatch(withType(FETCH_IDENTITIES_SUCCESS, { data }));
    } catch (error) {
        console.error(error.errors);
        dispatch(withType(FETCH_IDENTITIES_ERROR, { error }));
    }
};

export const appendIdentity = (id) => async (dispatch) => {
    try {
        dispatch(withType(POST_IDENTITIES_START));
        const res = await identitiesController.postIdentity(id);
        dispatch(withType(POST_IDENTITIES_SUCCESS, { data: res }));
    } catch (error) {
        console.error(error.errors);
        dispatch(withType(POST_IDENTITIES_ERROR, { error }));
    }
};


export const deleteIdentity = (id) => async (dispatch) => {
    try {
        dispatch(withType(FETCH_IDENTITIES_START));
        const res = await identitiesController.removeIdentity(id);
        dispatch(withType(FETCH_IDENTITIES_SUCCESS));
        dispatch(fetchIdentities());
    } catch (error) {
        console.error(error.errors);
        dispatch(withType(FETCH_IDENTITIES_ERROR, { error }));
    }
};
