// If you want to use generator, please do not change this file manually and let generator take care of that
import Journey from './Journey';
import Bee from './Bee';
import Context from './Context';
import Segments from './Segments';
import Profiles from './Profiles';
import Shopify from './Shopify';
import Organizations from './Organizations';
import Identities from './Identities';
import Statistics from './Statistics';

// EXPORTS
export default {
    Journey,
    Bee,
    Context,
    Segments,
    Profiles,
    Shopify,
    Organizations,
    Identities,
    Statistics
};
