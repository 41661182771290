import React, {useState, useContext, useEffect} from 'react';
import {
    FormControl,
    Select,
    MenuItem,
    Box,
    Radio,
    RadioGroup,
    FormControlLabel,
    TextField,
    Tooltip, Button
} from '@mui/material';
import ContentBox from '../../../ContentBox/ContentBox';
import {useJourney} from '../../../../../api/Journeys/journey_queries';
import { useImmer } from 'use-immer';
import {useMutation, useQuery} from '@tanstack/react-query';
import {apiV1OrgUri} from 'constants/apiRoutes';
import * as http from '../../../../../util/elemental_axios_client';
import produce from 'immer';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import CartFilter from 'components/organisms/QueryBuilder/CustomComponents/CartFilter';
import { useQueryClient } from '@tanstack/react-query';
import { setJourneyTrigger } from 'api/Journeys/journey_mutations';

const Placeholder = ({ children }) => {
    return <div style={{color: '#aaa'}}>{children}</div>;
};

const InputTest = (props) => {
    const { onChange, value, name, disabled, placeholder } = props;
    return (
        <TextField id='outlined-basic' onChange={onChange} value={value} name={name} variant='outlined' size='small' disabled={disabled} placeholder={placeholder} />
    );
};

const FlowTriggerFilter = (props) => {
    const queryClient = useQueryClient();
    const { properties, journeyId, hideSidebar } = props;

    const { data: audiences, isSuccess: audiencesSuccess, isLoading: audiencesIsLoading } = useQuery({
        queryKey: ['audiences', { 'per_page': 1000 }],
        queryFn: async () => {
            const pageOfAudiences = await http.get(`${apiV1OrgUri}/audiences?per_page=1000`);
            return pageOfAudiences.audiences;
        }
    });


    const [ triggerType, setTriggerType ] = useState('');
    const [ triggerMetric, setTriggerMetric ] = useImmer({
        metric_name: ''
    });
    const [ triggerProfilePropChange, setTriggerProfilePropChange ] = useImmer({
        prop_id: null,
        prop_old_value: '',
        prop_new_value: ''
    });
    const [ triggerAddToAudience, setTriggerAddToAudience ] = useImmer({
        audience_id: null
    });

    const metricTypeOptions = [{key: 'shopify_placed_order', label: 'Placed Shopify Order'}, {key: 'shopify_checkout_started', label: 'Started Shopify Checkout'}];

    const mutationSaveTrigger = useMutation({
        mutationFn: setJourneyTrigger,
        onSuccess: () => {
            queryClient.invalidateQueries(['journey', journeyId]);
            hideSidebar();
        }
    });

    const loadInitialTrigger = ( journey ) => {

        setTriggerType(journey.flow_trigger?.type);

        if(journey.flow_trigger?.type === 'metric') {
            setTriggerMetric(draft => { return { ...draft, ...journey.flow_trigger}; });
        } else if (journey.flow_trigger?.type === 'profile_prop_event') {
            setTriggerProfilePropChange(draft => { return { ...draft, ...journey.flow_trigger}; });
        } else if(journey.flow_trigger?.type === 'segment_add')  {
            setTriggerAddToAudience(draft => { return { ...draft, ...journey.flow_trigger}; });
        }
        else {
            console.log('no match');
        }
    };

    const { data: journey, isSuccess } = useJourney(journeyId, { onSuccess: loadInitialTrigger } );

    const saveJourneyTrigger = () => {
        let payload = {};

        if(triggerType === 'metric') {
            payload = triggerMetric;
        } else if (triggerType === 'profile_prop_event') {
            payload = triggerProfilePropChange;
        } else if(triggerType === 'segment_add')  {
            payload = triggerAddToAudience;
        } else {
            return;
        }

        const path = {
            metric: 'metric',
            profile_prop_event: 'profile_prop_change',
            segment_add: 'audience'
        };

        mutationSaveTrigger.mutate({ id: journeyId, path: path[triggerType], payload });
    };

    const updateMetricCartFilter = ( cartFilter ) => {
        if ( cartFilter ) {
            setTriggerMetric(draft => {
                draft.sub_filters = [cartFilter];
            });
        }
        else {
            setTriggerMetric(draft => {
                delete draft.sub_filters;
            });
        }
    };

    if (!isSuccess || !audiencesSuccess ) {
        return <Box>Loading</Box>;
    }

    const triggerMetricForm = (
        <div className={`${triggerType !== 'metric' && 'not_active'} filter--metric`}>
            <FormControl variant='outlined' size='small' className='data_types' disabled={triggerType !== 'metric'}>
                <Select
                    MenuProps={{ style: {zIndex: 35001} }}
                    labelId='demo-select-small'
                    id='demo-select-small'
                    displayEmpty
                    onChange={(e) => setTriggerMetric( draft => { draft.metric_name = e.target.value; })}
                    value={triggerMetric.metric_name}
                    renderValue={
                        triggerMetric.metric_name !== '' ? undefined : () => <Placeholder>select action</Placeholder>
                    }
                >
                    {metricTypeOptions.map((item, i) => <MenuItem key={i} value={item.key}>{item.label}</MenuItem>)}
                </Select>
            </FormControl>
            {<CartFilter initialCartFilter={ triggerMetric.sub_filters ? triggerMetric.sub_filters[0] : null } onChangeCartFilter={updateMetricCartFilter} />}
        </div>
    );

    const triggerProfilePropChangeForm = (
        <>
            <Box display='flex' flexGrow={1} alignItems='center' className={`${triggerType !== 'profile_prop_event' && 'not_active'} select_trigger`}>
                <FormControl variant='outlined' size='small' className='select_trigger' disabled={triggerType !== 'profile_prop_event'}>
                    <Select
                        MenuProps={{ style: {zIndex: 35001} }}
                        labelId='demo-select-small'
                        id='demo-select-small'
                        variant='outlined'
                        onChange={(e) => setTriggerProfilePropChange( draft => { draft.prop_id = e.target.value; })}
                        value={triggerProfilePropChange.prop_id}
                        displayEmpty
                        renderValue={
                            triggerProfilePropChange.prop_id !== null ? undefined : () => <Placeholder>Profile property</Placeholder>
                        }
                    >
                        {properties?.map((prop, i) => <MenuItem key={i} value={prop.id}>{prop.name_friendly}</MenuItem>
                        )}
                    </Select>
                </FormControl>
                <p>is changed</p>
            </Box>
            <div className={`${triggerType !== 'profile_prop_event' && 'not_active'} event--values`}>
                <p>from</p>
                <InputTest onChange={(e) => setTriggerProfilePropChange( draft => { draft.oldProp = e.target.value; })} value={triggerProfilePropChange.prop_old_value} disabled={triggerType !== 'profile_prop_event'} name='propValue.oldProp' placeholder='anything' />
                <p>to</p>
                <InputTest onChange={(e) => setTriggerProfilePropChange( draft => { draft.newProp = e.target.value; })} value={triggerProfilePropChange.prop_new_value} disabled={triggerType !== 'profile_prop_event'} name='propValue.newProp' placeholder='anything' />
            </div>
        </>
    );

    const triggerAddToAudienceForm = (
        <Box display='flex' flexGrow={1} alignItems='center'
             className={`${triggerType !== 'segment_add' && 'not_active'}`}>

            <FormControl variant='outlined' size='small' disabled={triggerType !== 'segment_add'}>
                <Select MenuProps={{style: {zIndex: 35001}}}
                        labelId='audiences-label'
                        id='audiences'
                        variant='outlined'
                        displayEmpty
                        onChange={(e) => setTriggerAddToAudience(draft => {
                            draft.audience_id = e.target.value;
                        })}
                        defaultValue={null}
                        value={triggerAddToAudience.audience_id}
                        renderValue={
                            triggerAddToAudience.audience_id !== null ?  undefined : () => <Placeholder>Select your
                                audience
                            </Placeholder>
                        }
                >
                    {audiences.map((item, i) => <MenuItem key={i} value={item.id}>{item.name}</MenuItem>)}
                </Select>
            </FormControl>
        </Box>
    );

    const triggerTypeForm = (
        <div className='trigger_exit_filters'>

            <div className='trigger_filter'>
                { mutationSaveTrigger.isError &&
                <Box>There was an error.</Box>
                }
                <FormControl>
                    <RadioGroup
                        aria-labelledby='demo-radio-buttons-group-label'
                        value={triggerType}
                        name='radio-buttons-group'
                        onChange={(e) => setTriggerType(e.target.value)}
                    >
                        <Box mt={4}>
                            <FormControlLabel value='metric' control={<Radio />} label='Person performs action' className={`${triggerType !== 'metric' && 'not_active--radio'}`} />
                            <div className='filter--metric'>{triggerMetricForm}</div>
                        </Box>
                        <Box mt={4}>
                            <FormControlLabel value='profile_prop_event' control={<Radio />} label={'Person\'s profile property changes'} className={`${triggerType !== 'profile_prop_event' && 'not_active--radio'}`} />
                            <div className='filter--metric'>{triggerProfilePropChangeForm}</div>
                        </Box>
                        <Box mt={4}>
                            <FormControlLabel value='segment_add' control={<Radio />} label='User is added to static audience' className={`${triggerType !== 'segment_add' && 'not_active--radio'}`} />
                            <div className='filter--metric'>{triggerAddToAudienceForm}</div>
                        </Box>
                    </RadioGroup>
                </FormControl>
            </div>
        </div>
    );

    return <ContentBox mutation={mutationSaveTrigger} handleClick={saveJourneyTrigger} handleClose={hideSidebar} confirmButton='Save' cancelButton='Close' content={triggerTypeForm} />;

};

export default FlowTriggerFilter;
