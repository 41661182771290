import React, {useState} from 'react';
import {withRouter} from 'react-router';
import {Box, Button, TextField, Typography} from '@mui/material';
import {DateTime} from 'luxon';
import {FormControl, FormControlLabel, Radio, RadioGroup} from '@mui/material';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import * as elemental_axios_client from '../../../util/elemental_axios_client';
import {useLocalStore} from 'store/index';
import {useHistory} from 'react-router-dom';
import {useUnexpectedErrorDialog} from '../../../contexts/UnexpectedErrorDialogContext';
import {enqueueSnackbar} from 'notistack';

const CreateCampaign = () => {

    const [setContextSidebar, resetContextSidebar] = useLocalStore(
        (state) => [state.setContextSidebar, state.resetContextSidebar]
    );


    const queryClient = useQueryClient();
    const history = useHistory();
    const showUnexpectedErrorDialog = useUnexpectedErrorDialog();
    const [ campaignName, setCampaignName ] = useState('');
    const [ messageType, setMessageType ] = useState('email');

    const handleCampaignName = (event) => {
        setCampaignName(event.target.value);
    };

    const mutationCreateCampaign = useMutation({
        mutationFn: ( payload) => {
            return elemental_axios_client.post('/campaigns', payload);
        },
        onSuccess: ( campaign) => {

            console.log('campaign created', campaign);
            queryClient.invalidateQueries( { queryKey: 'campaigns'});
            resetContextSidebar();
            if (campaign.message.message_type==='email'){history.push(`/campaigns/${campaign.id}/headers`);}
            if (campaign.message.message_type==='sms'){history.push(`/campaigns/${campaign.id}/message`);}
        }
    });

    const onCreate = async () => {
        const new_campaign = {
            name: campaignName,
            label: `campaign-${DateTime.now().toMillis().toString(36)}`,
            message_type: messageType
        };

        if(campaignName) {
            // showUnexpectedErrorDialog('Would have created something cool if these crazy kids did not get in the way');
            // enqueueSnackbar('another way to say things');
            mutationCreateCampaign.mutate(new_campaign);
        }
    };

    return (
        <Box className='flow-sidebar' style={{width: 450}}>
            <Box className='flow-sidebar__header'>
                <Typography component='div' variant='subtitle1' className='flow-sidebar_title' style={{borderBottom: 'none'}}>
                    Create a Campaign
                </Typography>
            </Box>
            <Box className='flow-sidebar__body' style={{minWidth: 'max-content'}}>
                <Box className='flow-sidebar__body-content'>
                    <Box display='flex' flexDirection='column'>
                        Campaign Name
                        <TextField size='small' margin='dense' placeholder='New Campaign' onChange={handleCampaignName} value={campaignName} variant='outlined' style={{marginTop: 10, background: '#FFF'}}/>

                        <Box display='flex' alignItems='flex-start'>
                            <FormControl sx={{textAlign: 'left', width:'100%'}}>
                                <RadioGroup
                                    aria-labelledby='message-type'
                                    name='message-type'
                                    value={messageType}
                                    onChange={(e) => setMessageType(e.target.value)}
                                >
                                    <FormControlLabel value='email' control={<Radio />} label='Email'/>
                                    <FormControlLabel value='sms' control={<Radio />} label='SMS'/>
                                </RadioGroup>
                            </FormControl>

                            <Button onClick={onCreate} className='new_primary_button' style={{margin: 0, marginTop: 10, marginLeft: 'auto', width: 'max-content', height: 'max-content'}} autoFocus>
                                Create
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default withRouter(CreateCampaign);
