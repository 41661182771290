import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

export const useLocalStore = create(
    persist(
        (set, get) => ({
            menuState: false,
            reportStates: {},
            editStates: {},
            contextSidebar: {},
            setMenuState: () => set((state) => ({ menuState: !state })),
            setReportMode: (journey) => set((state) => ({ reportStates: {...state.reportStates, [journey.id]: journey }})),
            setEditMode: (journey) => set((state) => ({ editStates: {...state.editStates, [journey.id]: journey }})),
            setContextSidebar: (payload) => set((state) => ({ contextSidebar: payload })),
            resetContextSidebar: () => set((state) => ({ contextSidebar:  { open: false, sidebar: false, element: false } })),
            getMenu: () => get().menuState,
            getReport: (id) => get().reportStates[id],
            getEdit: (id) => get().editStates[id],
            getContextSidebar: (id) => get().contextSidebar
        }),
        {
            name: 'elemental', // name of the item in the storage (must be unique)
            storage: createJSONStorage(() => sessionStorage) // (optional) by default, 'localStorage' is used
        }
    )
);
