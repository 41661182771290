/* eslint-disable react/display-name */
import React, { useEffect } from 'react';
import { Handle } from 'reactflow';
import { useParams } from 'react-router-dom';
import { Typography, Tooltip } from '@mui/material';
import { useJourney } from 'api/Journeys/journey_queries';
import { isEmpty } from 'lodash';
import { useLocalStore  } from 'store/index';

import { BoltRounded } from '@mui/icons-material';

const HANDLE_STYLE = {
    height: 1,
    width: 1,
    background: 'transparent',
    border: 0
};

const EnterNode = React.memo(({ data }) => {
    const { id } = useParams();

    const { isDisabled, stepId } = data;
    const { isFetching, data: journey } = useJourney(id);
    const [ hasTrigger, setHasTrigger ] = React.useState(false);

    const [setContextSidebar] = useLocalStore((state) => [state.setContextSidebar]);

    const handleSidebar = () => {
        const payload = {
            open: true,
            context: 'JourneyTrigger',
            element: {
                flowId: id
            }
        };

        setContextSidebar(payload);
    };

    React.useEffect(() => {
        const isTriggerSet = journey && journey?.status === 'draft' && isEmpty(journey?.flow_trigger);
        setHasTrigger(isTriggerSet);
    }, [journey]);

    const unsetTriggerClass = hasTrigger ? 'highlight' : '';

    return (
        <div style={{width: 250,  height: 30, margin: 'auto', cursor: 'pointer'}} onClick={handleSidebar}>
            <Tooltip title='Set Journey Trigger' open={Boolean(hasTrigger)} PopperProps={{ disablePortal: true }} placement='right'>
                <div className={!isDisabled ? `flow-enter-node ${unsetTriggerClass}` : `flow-enter-node ${unsetTriggerClass} disabled`}>
                    <Typography variant='caption' component='div' style={{fontWeight: 400, display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: 12}}>
                        <BoltRounded fontSize='small' color='primary' /> Enter Node
                    </Typography>
                </div>
            </Tooltip>
            <Handle
                type='source'
                position='bottom'
                style={HANDLE_STYLE}
                onConnect={(params) => console.log('handle onConnect', params)}
            />
        </div>
    );
});

export default EnterNode;
