import React from 'react';

import logoShort from 'assets/icons/main/el_logo_icon.svg';

const Loader = ({style}) => {
    return (
        <div id='cover-spin' className='loader' style={style}>
            <img src={logoShort} alt='elemental_loader' />
        </div>
    );
};

export default Loader;
