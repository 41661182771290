import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Bolt } from '@mui/icons-material';
import Alert from '@mui/material/Alert';
import FlowTriggerFilter from '../FlowFilters/FlowTriggerFilter/FlowTriggerFilter';
import { useJourney } from 'api/Journeys/journey_queries';

const TriggerSidebar = (props) => {
    const { flowId, hideSidebar } = props;
    const [singleFlow, setSingleFlow] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // getFlowById(flowId);
        setIsLoading(false);
    }, [flowId]);

    return (
        <>
            <div className='flow-sidebar' style={{width: '15vw'}}>
                <Box className='flow-sidebar__header'>
                    <Typography component='div' variant='body1' className='flow-sidebar_title no-edit'>
                        <Bolt/>
                        Person starts the Journey
                    </Typography>
                </Box>
                {/*{(neutralState.error?.errors || neutralState.error) &&*/}
                {/*    <Box mt={4} mr={2} ml={2}>*/}
                {/*        <Alert severity='warning'>Please check your input data again</Alert>*/}
                {/*    </Box>*/}
                {/*}*/}
                <Box className='flow-sidebar__body'>
                    {singleFlow?.status !== 'active' ?
                        isLoading  ? <div className='trigger_exit_filters'>Loading</div>
                            : <div className='branch--filter' style={{marginBottom: 25}}>
                                <FlowTriggerFilter
                                    journeyId={flowId}
                                    allAudiences={[]}
                                    properties={[]}
                                    hideSidebar={hideSidebar}
                                />
                              </div>
                        :
                            <Box px={2}>
                                <Alert severity='info'>
                                    <Typography variant='subtitle2'>{singleFlow?.flow_trigger?.name_friendly}</Typography>
                                    <p style={{fontSize:'12px', color: '#2B437A'}}>{singleFlow?.flow_trigger?.metric_name}</p>
                                </Alert>
                            </Box>
                    }
                </Box>
            </div>
        </>
    );
};
export default TriggerSidebar;
