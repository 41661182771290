import { ISSUE_SECTIONS } from 'constants/journeysConstants';

export const parseIssues = (issue) => {
    let isValidSection = true;
    let sub_section = issue.sub_sections.filter(item => item.issues.find(i => !i.is_valid));
    let section;

    while (isValidSection) {
        Object.entries(sub_section[0].issues).map((item) => {
            if(item[1].is_valid_section) {
                isValidSection = true;
                sub_section = item[1].sub_sections;
            } else {
                isValidSection = false;
                section = [
                    issue.model_id,
                    item[1].issue_codes_and_readable[0].code,
                    item[1].issue_codes_and_readable[0].readable.replace(' [actual readable coming soon]', ''),
                    ISSUE_SECTIONS[item[1].section]
                ];
            }
        });
    }

    return section;
};
