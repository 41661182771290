import React, { Suspense, useState } from 'react';
import { Box, Drawer, List, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CloseTwoTone } from '@mui/icons-material';
import clsx from 'clsx';
import ContextSidebar from 'components/organisms/ContextSidebar/ContextSidebar';
import { UnexpectedErrorDialogProvider } from '../../contexts/UnexpectedErrorDialogContext';
import { ConfirmProvider }  from 'material-ui-confirm';
import Logo from 'components/Logo';
import { useLocalStore } from 'store/index';
import { ListItemIcon } from '@mui/material';
import leftArrow from 'assets/icons/main/arrow_menu_close.svg';
import rightArrow from 'assets/icons/main/arrow_menu_open.svg';

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    hide: {
        display: 'none'
    },
    drawer: {
        width: drawerWidth,
        minWidth: 80,
        flexShrink: 0,
        whiteSpace: 'nowrap'
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: 'hidden',
        width: theme.spacing(8) + 1,
        [theme.breakpoints.up('sm')]: {
            width: 79
        }
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(4),
        maxWidth: '1480px',
        margin: 'auto'
    },
    content_flex: {
        flexGrow: 1,
        maxWidth: '1400px',
        margin: 'auto'
    }
}));

const fullscreen = [
    'Dashboard',
    'CreateJourney',
    'EditCampaigns'
];

const sidebarsWithCustomWidth = ['SingleAudience', 'CampaignStatistics', 'CreateJourney', 'Dashboard', 'CampaignsAll', 'Audience'];



const LayoutArrows = ({ open }) => {
    return (
        <ListItemIcon className='layout--arrows-list'>
            {open ? (
                <img src={leftArrow} alt='left' />
            ) : (
                <img src={rightArrow} alt='right' />
            )}
        </ListItemIcon>
    );
};




const Layout = ({ menu, main, pageName }) => {
    const classes = useStyles();

    const [getContextSidebar, resetContextSidebar] = useLocalStore((state) => [state.getContextSidebar, state.resetContextSidebar]);

    const [showQC, setShowQC] = useState(false);

    const context = getContextSidebar();

    const toggleQC = () => {
        setShowQC((state) => !state);
    };

    const hideSidebar = async () => {
        resetContextSidebar();
    };

    return (
        <UnexpectedErrorDialogProvider>
        <ConfirmProvider>
        <div className={classes.root}>
            <Drawer
                    variant='permanent'
                    style={{
                        minWidth: 80
                    }}
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: showQC,
                        [classes.drawerClose]: !showQC
                    })}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: showQC,
                            [classes.drawerClose]: !showQC
                        })
                    }}
                    >
                <div className='layout'>
                    <Logo open={showQC} />
                    {/* <List>
                        <Box display='flex' flexDirection='column' justifyContent='space-between' className='side_menu'>
                            <List>
                                <Tooltip title='Quick Create' placement='right'>
                                    <ListItem className='side_menu--item primary' button onClick={toggleQC}>
                                        <ListItemIcon className='side_menu--icons'><AddBoxTwoTone /></ListItemIcon>
                                        <ListItemText primary='Quick Create' />
                                    </ListItem>
                                </Tooltip>
                            </List>
                        </Box>
                    </List> */}
                    <div onClick={toggleQC} className='layout--arrows'>
                        <LayoutArrows open={showQC} />
                    </div>
                    <List>{menu}</List>
                </div>
            </Drawer>
            <main className={`${fullscreen.includes(main.props.subName) ? classes.content_flex : classes.content} ${showQC && 'drawer_open'}`}>
                <Suspense fallback={null}>{main}</Suspense>
            </main>
            <Drawer
                anchor='right'
                open={context?.open ?? false}
                onClose={hideSidebar}
                disableEnforceFocus
                style={{zIndex: 1350}}
            >
                {/*<Box className={`${sidebarsWithCustomWidth.includes(main.props.subName) && 'context__custom-width'} context`}>*/}
                <Box className={'context__custom-width context'}>
                    <IconButton size='small' variant='contained' onClick={hideSidebar} className='context-close'>
                        <CloseTwoTone />
                    </IconButton>
                    <ContextSidebar page={pageName} hideSidebar={hideSidebar} />
                </Box>
            </Drawer>
        </div>
        </ConfirmProvider>
        </UnexpectedErrorDialogProvider>
    );
};

export default Layout;
