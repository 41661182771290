import { initialState, neutralState } from '../helpers/defaultStateHelpers';
import { FETCH_PROFILES_SEGMENT_START, FETCH_PROFILES_SEGMENT_SUCCESS, FETCH_PROFILES_SEGMENT_ERROR, POST_PROFILES_SEGMENT_START, POST_PROFILES_SEGMENT_SUCCESS, POST_PROFILES_SEGMENT_ERROR, DELETE_PROFILES_SEGMENT_START, DELETE_PROFILES_SEGMENT_SUCCESS, DELETE_PROFILES_SEGMENT_ERROR, FETCH_PROFILES_START, FETCH_PROFILES_SUCCESS, FETCH_PROFILES_ERROR, POST_PROFILES_START, POST_PROFILES_SUCCESS, POST_PROFILES_ERROR, DELETE_PROFILES_START, DELETE_PROFILES_SUCCESS, DELETE_PROFILES_ERROR, SUPPRESS_PROFILES_START, SUPPRESS_PROFILES_SUCCESS, SUPPRESS_PROFILES_ERROR, FETCH_SINGLE_PROFILE_START, FETCH_SINGLE_PROFILE_SUCCESS, FETCH_SINGLE_PROFILE_ERROR, DELETE_SINGLE_PROFILE_START, DELETE_SINGLE_PROFILE_SUCCESS, DELETE_SINGLE_PROFILE_ERROR, FETCH_PROFILE_PROPERTIES_START, FETCH_PROFILE_PROPERTIES_SUCCESS, FETCH_PROFILE_PROPERTIES_ERROR } from './profiles_types';

const fetchProfilesReducer = (state = { ...initialState }, action) => {
    switch (action.type) {
    case FETCH_PROFILES_SEGMENT_START:
        return { ...state, fetching: true };
    case FETCH_PROFILES_SEGMENT_SUCCESS:
        return { ...state, ...neutralState, ready: true, fetched: true, data: action.data || [] };
    case FETCH_PROFILES_SEGMENT_ERROR:
        return { ...state, ...neutralState, error: action.error };
    case POST_PROFILES_SEGMENT_START:
        return { ...state, fetching: true };
    case POST_PROFILES_SEGMENT_SUCCESS:
        return { ...state, ...neutralState, ready: true, fetched: true, data: action.data || [] };
    case POST_PROFILES_SEGMENT_ERROR:
        return { ...state, ...neutralState, error: action.error };
    case DELETE_PROFILES_SEGMENT_START:
        return { ...state, fetching: true };
    case DELETE_PROFILES_SEGMENT_SUCCESS:
        return { ...state, ...neutralState, ready: true, fetched: true, data: action.data || [] };
    case DELETE_PROFILES_SEGMENT_ERROR:
        return { ...state, ...neutralState, error: action.error };
    case FETCH_PROFILES_START:
        return { ...state, fetching: true };
    case FETCH_PROFILES_SUCCESS:
        return { ...state, ...neutralState, ready: true, fetched: true, data: action.data || [] };
    case FETCH_PROFILES_ERROR:
        return { ...state, ...neutralState, error: action.error };
    case POST_PROFILES_START:
        return { ...state, fetching: true };
    case POST_PROFILES_SUCCESS:
        return { ...state, ...neutralState, ready: true, fetched: true, data: action.data || [] };
    case POST_PROFILES_ERROR:
        return { ...state, ...neutralState, error: action.error };
    case DELETE_PROFILES_START:
        return { ...state, fetching: true };
    case DELETE_PROFILES_SUCCESS:
        return { ...state, ...neutralState, ready: true, fetched: true, data: action.data || [] };
    case DELETE_PROFILES_ERROR:
        return { ...state, ...neutralState, error: action.error };
    case SUPPRESS_PROFILES_START:
        return { ...state, fetching: true };
    case SUPPRESS_PROFILES_SUCCESS:
        return { ...state, ...neutralState, ready: true, fetched: true, data: action.data || [] };
    case SUPPRESS_PROFILES_ERROR:
        return { ...state, ...neutralState, error: action.error };
    case FETCH_SINGLE_PROFILE_START:
        return { ...state, fetching: true };
    case FETCH_SINGLE_PROFILE_SUCCESS:
        return { ...state, ...neutralState, ready: true, fetched: true, data: action.data || [] };
    case FETCH_SINGLE_PROFILE_ERROR:
        return { ...state, ...neutralState, error: action.error };
    case DELETE_SINGLE_PROFILE_START:
        return { ...state, fetching: true };
    case DELETE_SINGLE_PROFILE_SUCCESS:
        return { ...state, ...neutralState, ready: true, fetched: true, data: action.data || [] };
    case DELETE_SINGLE_PROFILE_ERROR:
        return { ...state, ...neutralState, error: action.error };
    default:
        return state;
    }
};

export default fetchProfilesReducer;