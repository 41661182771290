import { initialState, neutralState } from '../helpers/defaultStateHelpers';
import { FETCH_IDENTITIES_START, FETCH_IDENTITIES_SUCCESS, FETCH_IDENTITIES_ERROR, POST_IDENTITIES_START, POST_IDENTITIES_SUCCESS, POST_IDENTITIES_ERROR } from './identities_types';

const fetchIdentitiesReducer = (state = { ...initialState }, action) => {
    switch (action.type) {
    case FETCH_IDENTITIES_START:
        return { ...state, fetching: true };
    case FETCH_IDENTITIES_SUCCESS:
        return { ...state, ...neutralState, ready: true, fetched: true, data: action.data || [] };
    case FETCH_IDENTITIES_ERROR:
        return { ...state, ...neutralState, error: action.error };
    case POST_IDENTITIES_START:
        return { ...state, fetching: true };
    case POST_IDENTITIES_SUCCESS:
        return { ...state, ...neutralState, ready: true, fetched: true, data: action.data || [] };
    case POST_IDENTITIES_ERROR:
        return { ...state, ...neutralState, error: action.error };
    default:
        return state;
    }
};

export default fetchIdentitiesReducer;
