import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTable, useResizeColumns, useFlexLayout, useRowSelect, useSortBy, useGlobalFilter, useExpanded, usePagination } from 'react-table';
import { useSticky } from 'react-table-sticky';
import { useVirtual } from 'react-virtual';
import { MenuItem, FormControlLabel, Checkbox, Box, TableContainer, Paper, Button, InputLabel } from '@mui/material';
import ColumnSelectButton from 'components/atoms/ColumnSelectButton/ColumnSelectButton';
import MultipleSortButton from 'components/atoms/MultipleSortButton/MultipleSortButton';
import SearchComponent from 'components/atoms/SearchComponent/SearchComponent';
import { IconButton, FormControl, Select } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon  from '@mui/icons-material/KeyboardArrowUp';
import ArrowUpwardTwoToneIcon from '@mui/icons-material/ArrowUpwardTwoTone';
import ArrowDownwardTwoToneIcon from '@mui/icons-material/ArrowDownwardTwoTone';
import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';
import PaginationComponent from '../Audiences/components/PaginationComponent';
import { AccessTimeRounded } from '@mui/icons-material';
import { isEmpty } from 'lodash';


const scrollbarWidth = () => {
    const scrollDiv = document.createElement('div');
    scrollDiv.setAttribute('style', 'width: 100px; height: 100px; overflow: scroll; position:absolute; top:-9999px;');
    document.body.appendChild(scrollDiv);
    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    document.body.removeChild(scrollDiv);
    return scrollbarWidth;
};


export default function TableComponent ({ tableHeader, columns, label = '', data, selectedCols, filterBy, pagination, initialPageSize=10, style, notSortable, showColumnsDropdown, hasDefaultSortOption, sortOption, handleNextPage, handlePreviousPage, handlePageSizeChange, pageIndex, showSortButton, onHeaderClick, sort, handleSortDirection, handleSortAccessor, canNextPage, canPreviousPage, filter, filterContent }) {
    const [ headersToShow, setHeadersToShow ] = useState({});

    const defaultColumn = useMemo(
        () => ({
            // width: 25,
            // minWidth: 10
            // maxWidth: 400
        }),
        []
    );

    const filterByFirstColumn = useCallback(
        (rows, query, preGlobalFilteredRows) => {
            return rows.filter(
                (row) => row.values[filterBy].toLowerCase().includes(preGlobalFilteredRows)
            );
        },
        []
    );

    const {
        getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, allColumns, setGlobalFilter, setPageSize, state: { pageSize } } = useTable({
        columns: columns, //.map(item => headersToShow[`header${item?.id}`]),
        defaultColumn,
        data,
        defaultCanSort: true,
        initialState: {
            pageSize: initialPageSize,
            pageIndex: pageIndex,
            hiddenColumns: selectedCols || [],
            sortBy: [
                { id: hasDefaultSortOption?sortOption:'created_at', desc: true}
            ]
        },
        globalFilter: filterByFirstColumn,
        manualSortBy: true
    }, useFlexLayout, useGlobalFilter, useSortBy, useSticky, useResizeColumns, useExpanded, usePagination, useRowSelect);

    const handleFilterInputChange = (e) => {
        const { value } = e.currentTarget;
        setGlobalFilter(value);
    };

    const scrollBarSize = useMemo(() => scrollbarWidth(), []);

    const parentRef = React.useRef();

    const rowVirtualizer = useVirtual({
        size: rows.length,
        parentRef,
        // estimateSize: React.useCallback(i => rows[i], []),
        overscan: 5
    });

    const filteredColumns = allColumns.slice(0, allColumns.length - 1);

    const shownHeaderColumns = headerGroups[0].headers.filter(item => headersToShow[item.id] !== false);

    const handleHeaderChange = (e) => {
        setHeadersToShow({
            ...headersToShow,
            [e.target.name]: e.target.checked
        });
    };

    // column visibility selector
    const listOfColumns = (
        filteredColumns.map(column => (
            <div key={column.id}>
                <MenuItem>
                    <FormControlLabel
                        control={
                            <Checkbox
                                {...column.getToggleHiddenProps()}
                                name={column.id}
                                color='primary'
                                checked={headersToShow[column.id] ?? true}
                                onChange={handleHeaderChange}
                            />
                        }
                        label={<Typography>{column.Header}</Typography>}
                    />
                </MenuItem>
            </div>
        ))
    );

    const listOfSortedColumns = (
        <Box sx={{minWidth:'300px', display: 'flex', flexDirection: 'column', justifyContent:'flex-start', alignItems:'flex-start', padding:'12px'}}>
            <Typography variant='subtitle2'>Sort by</Typography>
            <Box sx={{display: 'flex', marginTop:'12px', marginBottom:'12px'}}>
                <FormControl sx={{ my: 1, minWidth: 120, width:'50%'}} size='small'>
                    <InputLabel id='sort-field-sm'>Sort field</InputLabel>
                    <Select MenuProps={{ style: {zIndex: 35001} }}
                        labelId='sort-field-sm'
                        id='sort-field'
                        value={sort?.accessor}
                        label='Sort field'
                        onChange={(e)=>handleSortAccessor(e.target.value)}
                    >
                        {headerGroups[0].headers.map((column)=>(
                            <MenuItem value={column.id} key={column.id}>{column.Header}</MenuItem>

                        ))}
                    </Select>
                </FormControl>

                <FormControl sx={{ m: 1, minWidth: 140}} size='small'>
                    <InputLabel id='sort-direction-sm'>Sort direction</InputLabel>
                    <Select MenuProps={{ style: {zIndex: 35001} }}
                        labelId='sort-direction-sm'
                        id='sort-direction'
                        value={sort?.direction}
                        label='Sort direction'
                        onChange={(e)=>handleSortDirection(e.target.value)}
                    >
                        <MenuItem value={'none'}> 
                            <Box sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>None</Box>
                        </MenuItem>
                        <MenuItem value={'ASC'}> 
                            <Box sx={{display:'flex', alignItems:'center', justifyContent:'center'}}><ArrowUpwardTwoToneIcon fontSize='small' sx=
                                {{marginRight: '6px'}} />Ascending 
                            </Box>
                        </MenuItem>
                        <MenuItem value={'DESC'}>
                            <Box sx={{display:'flex', alignItems:'center', justifyContent:'center'}}><ArrowDownwardTwoToneIcon fontSize='small' sx=
                            {{marginRight: '6px'}}/>Descending
                            </Box>
                        </MenuItem>
                    </Select>
                </FormControl>
            </Box>
            {/* <Button variant='outlined'><AddIcon fontSize='small'/>Add new sort</Button> */}
        </Box>
    );

    return (
        <>
            <Box>{filter&&filterContent}</Box>
            <Box display='flex' justifyContent='space-between' alignItems='flex-start' sx={{marginBottom:2}}>
                <Box display='flex' justifyContent='flex-start' alignItems='flex-start'>
                    {showColumnsDropdown && <ColumnSelectButton content={listOfColumns} />}
                    {showSortButton && <MultipleSortButton content={listOfSortedColumns}/>}
                    {/* {filterBy && <SearchComponent onSearch={handleFilterInputChange} placeholder={`Search ${label}`} />} */}
                </Box>
                {pagination && <PaginationComponent onNextPage={()=>{handleNextPage();}} onPrevPage={()=>{handlePreviousPage();}} canNextPage={canNextPage} canPreviousPage={canPreviousPage} pageSize={pageSize} onPageSizeChange={(e)=>{setPageSize(e.target.value); handlePageSizeChange(e);}} />}
            </Box>
       
            <TableContainer component={Paper} className='table_wrapper'>
                <div className='table_container' style={style}>
                    <div {...getTableProps()} className='table sticky'>
                        <div ref={parentRef} style={{ display: 'block' }} >
                            <div className='thead header'>
                                {headerGroups.map((headerGroup, i) => (
                                    <div key={i} {...headerGroup.getHeaderGroupProps()} className='tr'>
                                        {shownHeaderColumns.map((column, ind) => (
                                            <div key={ind} {...column.getHeaderProps(!notSortable && column.getSortByToggleProps())} className='th' onClick={() => onHeaderClick(column)}>
                                                {column.render('Header')}
                                                {!notSortable && column.direction === 'ASC' ? (
                                                    <KeyboardArrowUpIcon sx={{fontSize: 'small', marginBottom: '-2px'}} />
                                                ) : column.direction === 'DESC' ? (
                                                    <KeyboardArrowDownIcon sx={{fontSize: 'small', marginBottom: '-2px'}} />
                                                ) : null}
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>

                            <div {...getTableBodyProps()} className='tbody body' style={{
                                height: `${rowVirtualizer.totalSize}px`,
                                width: '100%',
                                position: 'relative'
                            }}>
                                {rowVirtualizer.virtualItems.map((virtualRow) => {
                                    const row = rows[virtualRow.index];
                                    prepareRow(row);
                                    return (
                                        <div {...row.getRowProps()} className='tr' key={virtualRow.index} ref={virtualRow.measureRef} id={row.original.segment_id || row.original.id}>
                                            {row.cells.map((cell, i) => {
                                                if((isEmpty(headersToShow[cell.column.id]) && headersToShow[cell.column.id] !== false) || headersToShow[cell.column.id]) {
                                                    return (
                                                        <div {...cell.getCellProps()} className='td' hidden={true} id={row.original.segment_id || row.original.id} key={i}>
                                                            {cell.render('Cell')}
                                                        </div>
                                                    );
                                                }
                                            })}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </TableContainer>
        </>
    );
}
