import React, { useEffect } from 'react';
import { DateTime } from 'luxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import { dateFormat } from 'util/constants';

const CustomDateComponent = (props) => {

    const { setValue, value } = props;

    useEffect(() => {
        setValue(value);
    }, []);

    const handleChange = (newValue) => {
        const value = newValue;
        setValue(value);
    };

    return (
        <div>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DesktopDatePicker
                inputFormat={dateFormat}
                value={DateTime.fromISO(value) || null}
                onChange={handleChange}
                style={{zIndex: 3000}}
                renderInput={(params) =>  <TextField
                    {...params}
                    inputProps={{ ...params.inputProps, placeholder: 'Enter Date' }}
                    />}
            />
            </LocalizationProvider>
        </div>
    );
};

export default CustomDateComponent;
