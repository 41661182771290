import { initialState, neutralState } from '../helpers/defaultStateHelpers';
import {
    FETCH_FLOWS_START, FETCH_FLOWS_SUCCESS, FETCH_FLOWS_ERROR,
    FETCH_FLOW_START, FETCH_FLOW_SUCCESS, FETCH_FLOW_ERROR,
    FETCH_STEPS_START, FETCH_STEPS_SUCCESS, FETCH_STEPS_ERROR,
    FETCH_ACTIVATE_START, FETCH_ACTIVATE_SUCCESS, FETCH_ACTIVATE_ERROR
} from './journey_types';

const fetchJourneyReducer = (state = { ...initialState }, action) => {
    switch (action.type) {
    case FETCH_FLOWS_START:
        return { ...state, fetching: true };
    case FETCH_FLOWS_SUCCESS:
        return { ...state, ...neutralState, ready: true, fetched: true, data: action.data || [] };
    case FETCH_FLOWS_ERROR:
        return { ...state, ...neutralState, error: action.error };
    case FETCH_FLOW_START:
        return { ...state, fetching: true };
    case FETCH_FLOW_SUCCESS:
        return { ...state, ...neutralState, ready: true, fetched: true, journey: action.data || [] };
    case FETCH_FLOW_ERROR:
        return { ...state, ...neutralState, error: action.error };
    case FETCH_STEPS_START:
        return { ...state, fetching: true };
    case FETCH_STEPS_SUCCESS:
        return { ...state, ...neutralState, ready: true, fetched: true, steps: action.steps || [] };
    case FETCH_STEPS_ERROR:
        return { ...state, ...neutralState, error: action.error };
    case FETCH_ACTIVATE_START:
        return { ...state, fetching: true };
    case FETCH_ACTIVATE_SUCCESS:
        return { ...state, ...neutralState, ready: true, fetched: true, activate: action.steps || [] };
    case FETCH_ACTIVATE_ERROR:
        return { ...state, ...neutralState, error: action.error };
    default:
        return state;
    }
};

export default fetchJourneyReducer;
