import React, {useEffect, useMemo, useState} from 'react';
import {Autocomplete, Box, Button, Checkbox, FormControlLabel, List, ListItem, TextField} from '@mui/material';
import TableComponent from './TableComponent'; // TODO: can be deleted as soon as the new method is confirmed
import {Link} from 'react-router-dom';
import dynamicAudience from 'assets/icons/audiences/dynamic_aud.svg';
import staticAudience from 'assets/icons/audiences/static_aud.svg';
import {withStyles} from '@mui/styles';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import * as http from 'util/elemental_axios_client';
import * as elemental_axios_client from 'util/elemental_axios_client';
import {apiV1OrgUri} from 'constants/apiRoutes';
import {Loader} from 'components/atoms';
import {useImmer} from 'use-immer';
import MutationButton from 'components/atoms/Forms/MutationButton';

const GreenCheckbox = withStyles({
    root: {
        color: '#D5DDED',
        '&$checked': {
            color: '#408B85'
        }
    },
    checked: {}
})((props) => <Checkbox color='default' {...props} />);

const dynamicIcon = <img src={dynamicAudience} alt='uploadIcon'/>;
const staticIcon = <img src={staticAudience} alt='uploadIcon'/>;

export const AudienceSelector = ({ value, onChange, audiences }) => {

    const [ selectedAudiencesToInclude, setSelectedAudiencesToInclude ] = useState([]);
    const [ selectedAudiences, setSelectedAudiences ] = useImmer(value);

    const onClickIncludeAudiences = () => {
        setSelectedAudiences( draft => { draft = draft.push(...selectedAudiencesToInclude); });
        setSelectedAudiencesToInclude([]);
    };

    const onClickRemoveAudience = ( audienceToRemove ) => {
        setSelectedAudiences( draft => {
            const index = draft.findIndex(audience => audience.id === audienceToRemove.id);
            if (index !== -1) {
                draft.splice(index, 1); // Directly mutate the draft by removing the item
            }
        });
    };

    useEffect( () => {
        if ( onChange ){
            onChange(selectedAudiences);
        }
    }, [selectedAudiences]);

    const selectedAudienceIds = selectedAudiences.map(audience => audience.id);
    const availableAudiences = audiences.filter(audience => !selectedAudienceIds.includes(audience.id));
    const selectedAudiencesSorted = [...selectedAudiences].sort((a, b) => a.name.localeCompare(b.name));

    return (
        <>
                <Box display='inline-flex' alignItems='center' justifyContent='space-between' gap={2} sx={{ width: '100%' }}>

                    { availableAudiences?.length > 0 &&
                        <Autocomplete
                            multiple
                            disableCloseOnSelect
                            id='multiple-limit-tags'
                            options={availableAudiences}
                            getOptionLabel={(audience) => audience.name }
                            value={selectedAudiencesToInclude}
                            renderInput={(params) => (
                                <TextField {...params} fullWidth={true} label='Choose audiences ...'  fullWidth
                                />
                            )}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    {option.type === 'dynamic' ? dynamicIcon : staticIcon}
                                    {option.name}
                                </li>
                            )}
                            onChange={(e,value) => setSelectedAudiencesToInclude(value)}
                            size='small'
                            sx={{ width: '100%' }}

                        />
                    }
                </Box>
                { selectedAudiencesToInclude.length > 0 &&
                    <Box>
                    <Button
                        disabled={selectedAudiencesToInclude.length < 1}
                        className='new_primary_button'
                        onClick={onClickIncludeAudiences}
                    >Add {selectedAudiencesToInclude.length} audiences</Button>
                    </Box>
                }
                { selectedAudiencesSorted.length > 0 &&
                    <Box>
                    <List>

                        { selectedAudiencesSorted.map( (audience) => {
                            return ( <ListItem key={audience.id}>
                                {audience.type === 'dynamic' ? dynamicIcon : staticIcon}   { audience.name } <Button onClick={() => { onClickRemoveAudience(audience);}} >remove</Button> </ListItem> );
                        })
                        }
                    </List>
                    </Box>
                }
        </>

    );
};


const Audiences = ( { campaign, onSearch, onSave })  => {
    // selected from the table component
    const [selected, setSelected] = useState([]);
    // selected from the AudienceSelector
    const [selectedAudiences, setSelectedAudiences] = useState([]);

    const queryClient = useQueryClient();

    useEffect(()=>{
        const audienceId = campaign.segments.map(r => r.id);
        setSelected(audienceId);
    },[campaign]);

    const { data: allAudiences, isSuccess: allAudiencesSuccess } = useQuery({
        queryKey: ['audiences'],
        queryFn: async () => {
            return await http.get(`${apiV1OrgUri}/audiences?per_page=1000`);
        }
    });

    const mutationCampaignSetAudiences = useMutation({
        mutationFn: ( audienceIds ) => {
            return elemental_axios_client.post(`/campaigns/${campaign.id}/recipients`, audienceIds);
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries( { queryKey: ['campaigns', campaign.id] } );
            onSave();
        }
    });

    const handleClick = async (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const saveAudiences = async () => {
        mutationCampaignSetAudiences.mutate({ segments: selected });
    };

    const saveAudienceSelector = async () => {
        const audienceIds = selectedAudiences.map(audience => audience.id );
        mutationCampaignSetAudiences.mutate({ segments: audienceIds });
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const columns = useMemo(
        () => [
            { Header: 'Name', sticky: 'left', accessor: 'name', Cell ({row}) {
                const isItemSelected = isSelected(row.original.id);
                return (
                    <Box display='flex' alignItems='center'>
                        <FormControlLabel control={<GreenCheckbox style={{padding: '0px'}} checked={isItemSelected} onClick={(event) => handleClick(event, row.original.id)} key={row.original.id}/>}/>
                        <div className='audience_name'>
                            {row.original.type === 'dynamic' ? dynamicIcon : staticIcon}
                            <Link to={row.original.type === 'dynamic' ? `/audiences/${row.original.id}/${row.original.name}` : `/audiences-list/${row.original.id}/${row.original.name}`}>{row.original.name} </Link>
                        </div>
                    </Box>
                );
            }, width: 200 },
            { Header: 'Count', accessor: 'profiles_count', Cell ({row}) { return <div>{row.original.profiles_count}</div>; }, width: 150 },
            { Header: '', sticky: 'right', id: 'menu', Cell ({row}) { return true; }, width: 0}
        ],
        [selected]
    );

    if (!campaign || !allAudiencesSuccess) {
        return <Loader/>;
    }

    const data = allAudiences ? allAudiences.audiences : '';
    const columnsList = ['email', 'statistics.created_at', 'statistics.sent_total', 'statistics.avrg_revenue_per_recipient', 'statistics.revenue_last_30_days', 'statistics.conversions_total', 'statistics.conversions_percentage'];

    const selectorHasChanges = (JSON.stringify(selectedAudiences.map(a=>a.id).sort()) != JSON.stringify(campaign.segments.map(a=>a.id).sort()));

    return (
        <>
            <TableComponent label='audiences' showColumnsDropdown={false} columns={columns} data={data} selectedCols={columnsList} onSearch={onSearch} filterBy='name' className='shadow-hv'/>
            <Button onClick={saveAudiences} color='primary' variant='contained' sx={{marginTop: 1, marginRight: 0, marginLeft: '87%', width: 'max-content'}}>Save Changes</Button>
            Changes: {selectorHasChanges}

            <Box>
                <AudienceSelector audiences={allAudiences.audiences} onChange={setSelectedAudiences} value={campaign.segments} />
                <MutationButton
                    disabled={!selectorHasChanges}
                    mutation={mutationCampaignSetAudiences}
                    onClick={saveAudienceSelector} color='primary' variant='contained'
                    sx={{marginTop: 1, marginRight: 0, width: 'max-content'}}>
                    Save Audiences
                </MutationButton>
            </Box>
        </>
    );
};
export default Audiences;
