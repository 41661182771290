import React from 'react';
import { makeStyles } from '@mui/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Box} from '@mui/material';
import MutationButton from 'components/atoms/Forms/MutationButton';

const useStyles = makeStyles({
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flexGrow: 1
    }});

const ContentBox = (props) => {
    const { handleClick, handleClose, confirmButton, cancelButton = 'Cancel', showCancelButton = true, content, neutralState, children, style, mutation } = props;
    const classes = useStyles();

    return (
        <div className={classes.content} style={style}>
            <Box className='flow_query_filter create_audience'>{!content ? children : content}</Box>
            <Box display='flex' justifyContent='space-between' sx={{mx: 1, my: 1}}>
                {showCancelButton &&
                    <Button onClick={handleClose} className='new_primary_button' style={{marginRight: 'auto'}}>
                        {cancelButton}
                    </Button>
                }
                { mutation
                    ? <MutationButton
                        mutation={mutation}
                        onClick={handleClick}
                        className='new_primary_button'
                        type='submit'
                        style={{marginLeft: 'auto'}}
                        >mutation:
                        {confirmButton}
                    </MutationButton>
                    : <LoadingButton
                        loading={neutralState?.fetching}
                        onClick={handleClick}
                        className='new_primary_button'
                        type='submit'
                        style={{marginLeft: 'auto'}}
                    >
                        {confirmButton}
                    </LoadingButton>
                        }
            </Box>
        </div>
    );
};
export default ContentBox;
