import { initialState, neutralState } from '../helpers/defaultStateHelpers';
import { FETCH_SEGMENTS_START, FETCH_SEGMENTS_SUCCESS, FETCH_SEGMENTS_ERROR, CREATE_SEGMENTS_START, CREATE_SEGMENTS_SUCCESS, CREATE_SEGMENTS_ERROR, DELETE_SEGMENTS_START, DELETE_SEGMENTS_SUCCESS, DELETE_SEGMENTS_ERROR, EDIT_SEGMENTS_START, EDIT_SEGMENTS_SUCCESS, EDIT_SEGMENTS_ERROR, FETCH_SINGLE_SEGMENT_START, FETCH_SINGLE_SEGMENT_SUCCESS, FETCH_SINGLE_SEGMENT_ERROR } from './segments_types';

const fetchMessagesReducer = (state = { ...initialState }, action) => {
    switch (action.type) {
    case FETCH_SEGMENTS_START:
        return { ...state, fetching: true };
    case FETCH_SEGMENTS_SUCCESS:
        return { ...state, ...neutralState, ready: true, fetched: true, data: action.data || [] };
    case FETCH_SEGMENTS_ERROR:
        return { ...state, ...neutralState, error: action.error };
    case CREATE_SEGMENTS_START:
        return { ...state, fetching: true };
    case CREATE_SEGMENTS_SUCCESS:
        return { ...state, ...neutralState, ready: true, fetched: true, data: action.data || [] };
    case CREATE_SEGMENTS_ERROR:
        return { ...state, ...neutralState, error: action.error };
    case DELETE_SEGMENTS_START:
        return { ...state, fetching: true };
    case DELETE_SEGMENTS_SUCCESS:
        return { ...state, ...neutralState, ready: true, fetched: true, data: action.data || [] };
    case DELETE_SEGMENTS_ERROR:
        return { ...state, ...neutralState, error: action.error };
    case EDIT_SEGMENTS_START:
        return { ...state, fetching: true };
    case EDIT_SEGMENTS_SUCCESS:
        return { ...state, ...neutralState, ready: true, fetched: true, data: action.data || [] };
    case EDIT_SEGMENTS_ERROR:
        return { ...state, ...neutralState, error: action.error };
    case FETCH_SINGLE_SEGMENT_START:
        return { ...state, fetching: true };
    case FETCH_SINGLE_SEGMENT_SUCCESS:
        return { ...state, ...neutralState, ready: true, fetched: true, data: action.data || [] };
    case FETCH_SINGLE_SEGMENT_ERROR:
        return { ...state, ...neutralState, error: action.error };
    default:
        return state;
    }
};

export default fetchMessagesReducer;