import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { Box, TextField, Typography, Button } from '@mui/material';
import { DateTime } from 'luxon';
import { useMutation } from '@tanstack/react-query';
import { setCreateJourney } from 'api/Journeys/journey_mutations';
import { useLocalStore  } from 'store/index';

const CreateJourney = ({ show, handleClose, history }) => {
    const [ journeyName, setJourneyName ] = useState('');

    const [resetContextSidebar] = useLocalStore((state) => [state.resetContextSidebar]);

    const handleJourneyName = (event) => {
        setJourneyName(event.target.value);
    };

    const { mutate: createJourneyMutation } = useMutation(setCreateJourney, {
        onSuccess: async (data) => {
            resetContextSidebar();
            window.location = `/journeys/${data.id}`;
        }
    });

    const onCreate = async () => {
        const payload = {
            name: journeyName,
            label: `journey-${DateTime.now().toMillis().toString(36)}`
        };

        if(journeyName) {
            await createJourneyMutation({payload});
        }
    };

    return (
        <Box className='flow-sidebar' style={{width: 450}}>
            <Box className='flow-sidebar__header'>
                <Typography component='div' variant='subtitle1' className='flow-sidebar_title' style={{borderBottom: 'none'}}>
                    New Journey
                </Typography>
            </Box>
            <Box className='flow-sidebar__body' style={{width: 500}}>
                <Box className='flow-sidebar__body-content'>
                    <Box display='flex' flexDirection='column'>
                        Journey Name
                        <TextField size='small' margin='dense' fullWidth placeholder='New Journey' onChange={handleJourneyName} value={journeyName} variant='outlined' style={{marginTop: 10, background: '#FFF'}}/>

                        <Box display='flex' alignItems='flex-start'>
                            <Button onClick={onCreate} className='new_primary_button' style={{margin: 0, marginTop: 10, marginLeft: 'auto', width: 'max-content', height: 'max-content'}} autoFocus>
                                Create
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default withRouter(CreateJourney);
