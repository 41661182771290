import React, {useCallback, useContext, useEffect, useState} from 'react';
import { MessageSidebar, WaitSidebar, BranchFilterSidebar, SMSSidebar } from 'components/molecules/Journeys/Sidebar';
import { Alert, Box, Divider, Select, FormControl, InputLabel, IconButton, InputBase, Tooltip, Typography } from '@mui/material';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useNodesState, useEdgesState } from 'reactflow';
import { useParams } from 'react-router-dom';
// import { FlowsContext } from '../../../contexts/FlowsContext';
import { ConfirmDialog } from 'components/molecules/ConfirmDialog';
import { useJourney, useJourneyStep, setFlipBranch, setStepLabel, unsetStep } from 'api/Journeys';
import { useLocalStore } from 'store/index';

import { isEmpty } from 'lodash';

import { CallSplitRounded, DeleteRounded, EmailTwoTone, CompareArrowsTwoTone, TimelapseTwoTone, Sms } from '@mui/icons-material';

const JourneySidebar = ({ history }) => {
    const queryClient = useQueryClient();
    const { id: journeyId } = useParams();

    const [getContextSidebar, resetContextSidebar] = useLocalStore((state) => [state.getContextSidebar, state.resetContextSidebar]);
    const context = getContextSidebar();
    // left for now since it is used to pass in the prev edge key for branches which powers the deletion branch chooser and is used in the branch component at the moment
    const stepState = context.element;
    const stepId = stepState.stepId;

    const [ showLabel, setShowLabel ] = useState(false);
    const [ label, setLabel ] = useState(false);
    const [ showDialogConfirmDeleteStep, setShowDialogConfirmDeleteStep ] = useState(false);

    const { data: journeyStep, isSuccess: isSuccessJourneyStep } = useJourneyStep(journeyId, stepId);
    const { data: journey, isSuccess: isSuccessJourney } = useJourney(journeyId);

    const flipSplit = useMutation(setFlipBranch, {
        onSuccess: () => {
            queryClient.invalidateQueries(['journey', journeyId]);
            queryClient.invalidateQueries(['adjacencyList', journeyId]);
        }
    });

    const updateLabel = useMutation(setStepLabel, {
        onSuccess: () => {
            queryClient.invalidateQueries(['journey_step', stepId]);
        }
    });

    const removeStep = useMutation(unsetStep, {
        onSuccess: () => {
            queryClient.invalidateQueries(['journey', journeyId]);
            queryClient.invalidateQueries(['adjacencyList', journeyId]);
        }
    });

    const [nodes, setNodes, onNodesChange] = useNodesState([]);

    const [ branchNextEdgeKeyToFollowOnDelete, setBranchNextEdgeKeyToFollowOnDelete] = useState('');

    const hasCustomWidth = (journey?.status === 'active' && journeyStep?.type === ('email' || 'sms')) || journeyStep?.type === ('email' || 'sms');

    // selecting the branch edge to follow when deleting a branch
    const handleBranchNextEdgeKeySelection = (event) => {
        setBranchNextEdgeKeyToFollowOnDelete(event.target.value);
    };

    useEffect(() => {
        journeyStep && setLabel(journeyStep.label);
    }, [journeyStep]);

    const onFlip = useCallback(() => {
        const handleFlip = async () => {
            await flipSplit.mutate({id: journeyId, stepId});
        };
        handleFlip();
    }, [stepId, journeyId]);

    const onRemove = useCallback(() => {
        const handleRemove = async () => {
            await removeStep.mutate({id: journeyId, stepId, payload: getStepPayload()});
        };

        if(getStepPayload()) {
            handleRemove();
            setShowDialogConfirmDeleteStep(false);
            resetContextSidebar();
        }
    }, [journeyId, stepId, branchNextEdgeKeyToFollowOnDelete, journey]);

    const getStepPayload = () => {
        if (journeyStep?.type === 'branch_filter') {
            return { follow_key_from: branchNextEdgeKeyToFollowOnDelete };
        }
        if (journeyStep?.type === 'branch_filter' && !isEmpty(branchNextEdgeKeyToFollowOnDelete)) {
            return false;
        }

        return {};
    };

    const onChangeLabel = () => {
        setShowLabel((state) => !state);
    };

    const handleStepLabelChange = (e) => {
        setLabel(e.target.value);
    };

    const onStepLabelUpdate = useCallback(() => {
        const update = async () => {
            await updateLabel.mutate({id: journeyId, stepId, payload: {label}} );
        };

        if(journeyStep?.label !== label) {
            setNodes(nodes.filter(el => el.data.stepId !== stepId));
            update();
        }
        setShowLabel(false);
    }, [journeyStep, setShowLabel, nodes, label, journeyId, setNodes, stepId]);


    const confirmDialogDeleteStep = (type) => {
        switch(type) {
        case 'branch_filter':
            return (
                <ConfirmDialog show={showDialogConfirmDeleteStep} onConfirm={onRemove} onDecline={() => setShowDialogConfirmDeleteStep(false)} onClose={() => setShowDialogConfirmDeleteStep(false)} title={'Delete step'} onDeclineLabel={'Cancel'} onConfirmLabel={'Delete'} disableEnforceFocus>
                    Select the branch that you want to keep.
                    <FormControl sx={{mt: 2}} fullWidth size='small' style={{zIndex: 1500}}>
                        <InputLabel id='branch'>Branch</InputLabel>
                        <Select native margin='dense' p={0} label='Branch' value={branchNextEdgeKeyToFollowOnDelete} onChange={handleBranchNextEdgeKeySelection} required>
                            <option value='match'  style={{zIndex: 1500}}>Match</option>
                            <option value='no_match'  style={{zIndex: 1500}}>No Match</option>
                        </Select>
                    </FormControl>
                </ConfirmDialog>
            );
        default:
            return <ConfirmDialog show={showDialogConfirmDeleteStep} onClose={() => setShowDialogConfirmDeleteStep(false)} onDecline={() => setShowDialogConfirmDeleteStep(false)} onConfirm={onRemove} title={'Delete step'} onDeclineLabel={'Cancel'} onConfirmLabel={'Delete'} content={'Are you sure? This action cannot be undone.'}/>;
        }
    };

    if ( isSuccessJourneyStep && isSuccessJourney ) {

        const sidebars = {
            'branch_filter': [<CallSplitRounded color='success' />, <BranchFilterSidebar stepId={journeyStep.id} keyFrom={stepState.follow_key_from} hideSidebar={resetContextSidebar} />],
            'wait': [<TimelapseTwoTone color='warning' />, <WaitSidebar stepId={journeyStep.id} keyFrom={stepState.follow_key_from} hideSidebar={resetContextSidebar} />],
            'email': [<EmailTwoTone color='secondary'/>, <MessageSidebar stepId={journeyStep.id} hideSidebar={resetContextSidebar} history={history} />],
            'sms':[<Sms color='secondary'/>,<SMSSidebar stepId={journeyStep.id} keyFrom={stepState.follow_key_from} hideSidebar={resetContextSidebar} history={history}  />]
        };

        return (
            <Box className='flow-sidebar' display='flex' style={{width: hasCustomWidth ? '22vw' : '15vw'}}>
                <Box className='flow-sidebar__header'>
                    <div onBlur={onStepLabelUpdate}>
                        {showLabel ? (
                            <InputBase id='journey-name' value={label} onChange={handleStepLabelChange}
                                       className='flow-sidebar__title' autoFocus/>
                        ) : (
                            <Box onClick={onChangeLabel}>
                                <Tooltip placement='right' title={'Click to change'}>
                                    <Typography component='div' variant='subtitle1' className='flow-sidebar_title'>
                                        {label}
                                    </Typography>
                                </Tooltip>
                            </Box>
                        )}
                    </div>
                    <Box display='inline-flex' alignItems='center'>
                        {journeyStep.type === 'branch_filter' &&
                            <>
                                <Tooltip placement='top' title='Flip Split'>
                                    <IconButton size='small' className='flow-sidebar__actions' onClick={onFlip}>
                                        <CompareArrowsTwoTone fontSize='inherit'/>
                                    </IconButton>
                                </Tooltip>
                                <Divider orientation='vertical' flexItem/>
                            </>
                        }
                        <Tooltip placement='top' title='Delete Step'>
                            <IconButton size='small' className='flow-sidebar__actions'
                                        onClick={() => setShowDialogConfirmDeleteStep(true)}>
                                <DeleteRounded fontSize='inherit'/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
                {/*{(neutralState.error?.errors || neutralState.error) &&*/}
                {/*    <Box mt={4} mr={2} ml={2}>*/}
                {/*        <Alert severity='warning'>Please check your conditions again</Alert>*/}
                {/*    </Box>}*/}
                {/*{sidebars[journeyStep.type][1]}*/}
                {confirmDialogDeleteStep(journeyStep.type)}
            </Box>
        );
    }
    else {
        return <Box>Loading ..</Box>;
    }
};

export default JourneySidebar;
