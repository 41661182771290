/* eslint-disable react/display-name */
import React from 'react';
import { useParams } from 'react-router-dom';
import { Handle } from 'reactflow';
import { Typography, Tooltip } from '@mui/material';
import { useJourney } from 'api/Journeys/journey_queries';
import { FlagRounded } from '@mui/icons-material';
import { useLocalStore  } from 'store/index';

const HANDLE_STYLE = {
    background: 'transparent',
    border: 0
};

const ExitNode = React.memo(({ data }) => {
    const { id } = useParams();

    const { isDisabled, stepId } = data;
    const [ hasTrigger, setHasTrigger ] = React.useState(false);
    const { isFetching, data: journey } = useJourney(id);

    const [setContextSidebar] = useLocalStore((state) => [state.setContextSidebar]);

    const handleSidebar = () => {
        const payload = {
            open: true,
            context: 'JourneyDropout',
            element: {
                flowId: id
            }
        };
        setContextSidebar(payload);
    };

    React.useEffect(() => {
        const isTriggerSet = journey && journey?.status === 'draft' && !(journey?.dropout_filter_draft || journey?.dropout_filter);
        setHasTrigger(isTriggerSet);
    }, [journey]);

    const unsetTriggerClass = hasTrigger ? 'highlight' : '';

    return (
        <div style={{width: 250, margin: 'auto', cursor: 'pointer'}} onClick={handleSidebar}>
            <Handle
                type='target'
                position='top'
                id='primary'
                style={HANDLE_STYLE}
                onConnect={(params) => console.log('handle onConnect', params)}
            />
            <Tooltip title='Set Exit Trigger' open={Boolean(hasTrigger)} PopperProps={{ disablePortal: true }} placement='right'>
                <div className={!isDisabled ? `flow-exit-node ${unsetTriggerClass}` : `flow-exit-node ${unsetTriggerClass} disabled`} onClick={handleSidebar}>
                    <Typography variant='caption' component='div' style={{fontWeight: 500, display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: 10}}>
                        <FlagRounded fontSize='small' color='primary' />
                    </Typography>
                </div>
            </Tooltip>
        </div>
    );
});

export default ExitNode;
