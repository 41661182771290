import { createFromCall } from 'util/Type';

const PREFIX = 'Journey/';

export const CALLS = {
    FETCH_FLOWS: `${PREFIX}FETCH_FLOWS_START`,
    FETCH_FLOW: `${PREFIX}FETCH_FLOW_START`,
    FETCH_ACTIVATE: `${PREFIX}FETCH_ACTIVATE_START`,
    FETCH_STEPS: `${PREFIX}FETCH_STEPS_START`
};

export const FETCH_FLOWS_START = CALLS.FETCH_FLOWS;
export const FETCH_FLOWS_SUCCESS = createFromCall(CALLS.FETCH_FLOWS, 'SUCCESS');
export const FETCH_FLOWS_ERROR = createFromCall(CALLS.FETCH_FLOWS, 'ERROR');

export const FETCH_FLOW_START = CALLS.FETCH_FLOW;
export const FETCH_FLOW_SUCCESS = createFromCall(CALLS.FETCH_FLOW, 'SUCCESS');
export const FETCH_FLOW_ERROR = createFromCall(CALLS.FETCH_FLOW, 'ERROR');

export const FETCH_STEPS_START = CALLS.FETCH_STEPS;
export const FETCH_STEPS_SUCCESS = createFromCall(CALLS.FETCH_STEPS, 'SUCCESS');
export const FETCH_STEPS_ERROR = createFromCall(CALLS.FETCH_STEPS, 'ERROR');

export const FETCH_ACTIVATE_START = CALLS.FETCH_ACTIVATE;
export const FETCH_ACTIVATE_SUCCESS = createFromCall(CALLS.FETCH_ACTIVATE, 'SUCCESS');
export const FETCH_ACTIVATE_ERROR = createFromCall(CALLS.FETCH_ACTIVATE, 'ERROR');
