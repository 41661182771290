export const DAYS = [{ key: 'monday', label: 'M' }, { key: 'tuesday', label: 'T' }, { key: 'wednesday', label: 'W' }, { key: 'thursday', label: 'T' }, { key: 'friday', label: 'F' }, { key: 'saturday', label: 'S' }, { key: 'sunday', label: 'S' }];

export const dataTypes = ['string', 'integer', 'decimal', 'boolean', 'date'];

export const dateFormat = 'MMMM dd, yyyy';
export const dateMaskFormat = '__/__/____';
export const dateTimeFormat = 'MMMM dd, yyyy hh:mm a';

export const selectedColsProfilesTable = [
    'statistics.bounces_percentage',
    'statistics.complaints_percentage',
    'statistics.conversions_percentage',
    'statistics.deliveries_percentage',
    'statistics.revenue_total_last_30_days',
    'statistics.last_sent_at',
    'statistics.sends_total',
    'statistics.sends_total_last_30_days',
    'statistics.next_send_scheduled_at',
    'statistics.first_ordered_at',
    'statistics.last_ordered_at',
    'propsMapped.phone_number.value',
    'propsMapped.country.value',
    'propsMapped.state.value',
    'propsMapped.address.value'

];
export const selectedColsAudiencesTable = ['email', 'statistics.generated_at', 'statistics.sends_total', 'statistics.revenue_average_per_profile', 'statistics.revenue_total_last_30_days', 'statistics.conversions_total', 'statistics.conversions_percentage'];
