import { createFromCall } from '../../util/Type';

const PREFIX = 'MessagesList/';

export const CALLS = {
    FETCH_MESSAGES: `${PREFIX}FETCH_MESSAGES_START`,
    FETCH_STATS: `${PREFIX}FETCH_STATS_START`
};

export const FETCH_STATS_START = CALLS.FETCH_STATS;
export const FETCH_STATS_SUCCESS = createFromCall(CALLS.FETCH_STATS, 'SUCCESS');
export const FETCH_STATS_ERROR = createFromCall(CALLS.FETCH_STATS, 'ERROR');