/* eslint-disable react/display-name */
import React from 'react';
import { withRouter } from 'react-router';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { Handle, Position } from 'reactflow';
import { useJourneyStep, useJourneyStepStats } from 'api/Journeys/journey_queries';
import StepHeader from 'components/atoms/Step/StepHeader';
import StepBody from 'components/atoms/Step/StepBody';
import { useLocalStore } from 'store/index';

const HANDLE_STYLE = {
    height: 13,
    width: 13,
    position: 'absolute',
    background: 'transparent', //'#E6F5FD',
    boxSizing: 'border-box',
    border: '2px solid transparent',
    bottom: '0px',
    left: '50%'
};


const SMSDraftNode = ({ data, status, handleSidebar }) => {
    return (
        <>
            <StepHeader stepData={data} status={status} handleSidebar={handleSidebar}/>
            {data?.can_activate &&
                <StepBody status={data} handleSidebar={handleSidebar}>
                    <div className='flow-node__body' onClick={handleSidebar}>
                        <Typography variant='body2' style={{fontSize: 11}}>
                            {data?.message?.text}
                        </Typography>
                    </div>
                </StepBody>
            }
        </>
    );
};

const SMSActiveNode = ({ data, status, handleSidebar }) => {
    return (
        <>
            <StepHeader stepData={data} status={status} handleSidebar={handleSidebar}/>
            {data?.can_activate &&
                <StepBody status={data} handleSidebar={handleSidebar}>
                    <div className='flow-node__body' onClick={handleSidebar}>
                        <Typography variant='body2' style={{fontSize: 11}}>
                            {data?.message?.text}
                        </Typography>
                    </div>
                </StepBody>
            }
        </>
    );
};

const SMSReportNode = ({ data, status, id, stepId, handleSidebar }) => {
    const { data: stepStats } = useJourneyStepStats(id, stepId);

    const statsReport =
        <Box display='flex' justifyContent='center' className='reportNode' style={{marginInline: 'auto', textAlign: 'center'}}>
            <Typography component='div' variant='caption' style={{width:'100%', display: 'flex', textAlign: 'center', flexDirection: 'column'}}>
                {stepStats?.conversions?.orders} / ${stepStats?.conversions.revenue}
                <small>Orders Placed</small>
            </Typography>
            <Typography component='div' variant='caption' style={{width:'100%', display: 'flex', textAlign: 'center', flexDirection: 'column'}}>
                {stepStats?.message?.opens_percentage}%
                <small>Open Rate</small>
            </Typography>
            <Typography component='div' variant='caption' style={{width:'100%', display: 'flex', textAlign: 'center', flexDirection: 'column'}}>
                {stepStats?.message?.clicks_percentage}%
                <small>Click Rate</small>
            </Typography>
            {/* <Box className='user-stats' p={0}>
                <Typography component='div' variant='caption' style={{width:'100%', display: 'inline-flex'}}>
                    Waiting: {stepStats?.wait?.waiting}
                </Typography>
                <Typography component='div' variant='caption' style={{width:'100%', display: 'inline-flex'}}>
                    Skipped: {stepStats?.bypass_filter?.skipped}
                </Typography>
                <Typography component='div' variant='caption' style={{width:'100%', display: 'inline-flex'}}>
                    Journeyed: {stepStats?.step?.journeyed}
                </Typography>
            </Box> */}
        </Box>;

    return (
        <>
            <StepHeader stepData={data} status={status} handleSidebar={handleSidebar}/>
            <StepBody status={data} stats={statsReport} handleSidebar={handleSidebar}>
                <div className='flow-node__body' onClick={handleSidebar}>
                    <Typography variant='body2' style={{fontSize: 11}}>
                        {data?.message?.text}
                    </Typography>
                    {statsReport}
                </div>
            </StepBody>
        </>
    );
};


const SMSNode = React.memo(({ data, isConnectable, history }) => {
    const { id } = useParams();
    const { isDisabled, status, stepId } = data;
    const stepData = useJourneyStep(id, stepId);
    const { data: stepStats } = useJourneyStepStats(id, stepId);

    const [getReport, getEdit, setContextSidebar] = useLocalStore((state) => [state.getReport, state.getEdit, state.setContextSidebar]);

    const reportState = getReport(id);
    const editState = getEdit(id);

    const handleSidebar = (event) => {
        if(event.target.id !== 'campaign-subject') {
            const payload = {
                open: true,
                context: 'Journeys',
                element: {
                    type: stepData.type,
                    stepId: data.stepId
                }
            };

            setContextSidebar(payload);
        }
    };

    const isInEditMode = !editState?.state ? <SMSDraftNode data={stepData.data} status={status} handleSidebar={handleSidebar}/> : <SMSActiveNode data={stepData.data} status={status} handleSidebar={handleSidebar}/>;

    return (
        stepData.data ?
            <div className={!isDisabled ? 'flow-message-node' : 'flow-message-node disabled'}>
                <Handle
                    type='target'
                    position={Position.Top}
                    style={{ height: 0, border: 0, background: 'transparent' }}
                    isConnectable={isConnectable}
                />
                {reportState?.state ? <SMSReportNode data={stepData.data} status={status} id={id} stepId={stepId} handleSidebar={handleSidebar}/> : isInEditMode}
                <Handle
                    type='source'
                    position={Position.Bottom}
                    style={HANDLE_STYLE}
                    isConnectable={isConnectable}
                />
            </div>
            :
            <></>
    );
});
export default withRouter(SMSNode);
