import React, {useEffect, useState} from 'react';
import { Box, Button, Dialog, DialogTitle, DialogContent, Slide, DialogActions } from '@mui/material';

const Transition = React.forwardRef(function Transition (props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});


const ConfirmDialog = ({ children, show, onDecline, onConfirm, title, onDeclineLabel, onConfirmLabel, handleClose, content }) => {
    const [ header, setHeader ] = useState(title ? title : 'Confirm Dialog');
    const [ showDialog, setShowDialog ] = useState(show);
    const [ confirmLabel, setConfirmLabel ] = useState(onConfirmLabel ? onConfirmLabel : 'Accept');
    const [ declineLabel, setDeclineLabel ] = useState(onDeclineLabel ? onDeclineLabel : 'Decline');

    useEffect(() => {
        setShowDialog(show);
    }, [show]);

    onDecline = onDecline ? onDecline : () => setShowDialog(false);
    onConfirm = onConfirm ? onConfirm : () => setShowDialog(false);
    handleClose = handleClose ? handleClose : () => setShowDialog(false);

    return (
        <Dialog
            open={show}
            onClose={handleClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            TransitionComponent={Transition}
            disableEnforceFocus
            style={{zIndex: 1400}}
            disableEscapeKeyDown
        >
            <DialogTitle id='alert-dialog-title'>
                {header}
            </DialogTitle>
            <DialogContent>
                <Box component='form' sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    {!children ? content : children}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onDecline} variant='outlined' color='default'>
                    {declineLabel}
                </Button>
                <Button onClick={onConfirm} variant='contained' color='secondary'>
                    {confirmLabel}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;
