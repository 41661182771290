import React from 'react';
import { Box, IconButton, FormControl, Select, MenuItem } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const PaginationComponent = (props) => {
    const { canPreviousPage, canNextPage, onPrevPage, onNextPage, pageSize, onPageSizeChange } = props;

    return (
        <Box>
            <FormControl>
                <Select MenuProps={{ style: {zIndex: 35001} }}
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={pageSize}
                    onChange={onPageSizeChange}
                    size='small'
                    style={{ width: '75px'}}
                    >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                </Select>
            </FormControl>
            <IconButton size='small' className='icon-button' onClick={onPrevPage} disabled={!canPreviousPage} style={{margin: '0px 10px'}}>
                <ChevronLeftIcon />
            </IconButton>
            <IconButton size='small' className='icon-button' onClick={onNextPage} disabled={!canNextPage}>
                <ChevronRightIcon />
            </IconButton>
        </Box>
    );
};
export default PaginationComponent;
