import React, {Fragment} from 'react';
import {useHistory, useLocation} from 'react-router';
import {Box, List, ListItem, ListItemIcon, ListItemText, Tooltip} from '@mui/material';
import {
    CollectionsBookmarkTwoTone,
    EmailTwoTone,
    GroupsTwoTone,
    MeetingRoomTwoTone,
    SettingsApplicationsTwoTone
} from '@mui/icons-material';
import {useAuth} from 'contexts/AuthContext';
import {ImportContactsTwoTone} from '@mui/icons-material';

const menuLinks = [
    [
        // { url: '/dashboard', label: 'Delivery Statistics', icon: <AnalyticsTwoTone /> },
        // { url: '/journeys', label: 'Journeys', icon: <AccountTreeTwoTone /> },
        { url: '/campaigns', label: 'Campaigns', icon: <EmailTwoTone /> },
        // { url: '/batches', label: 'Batches', icon: <DynamicFeedTwoTone /> },
        { url: '/audiences', label: 'Audiences', icon: <GroupsTwoTone /> },
        { url: '/import-sessions', label: 'Imports', icon: <ImportContactsTwoTone /> },
        { url: '/library', label: 'Template Library', icon: <CollectionsBookmarkTwoTone /> },
        // { url: '/analytics', label: 'Analytics', icon: <Analytics /> },
        { url: '/settings', label: 'Settings', icon: <SettingsApplicationsTwoTone /> }
    ],
    []
];

const SideMenu = () => {

    const history = useHistory();
    const links = menuLinks;

    const { logoutAction, currentUser, organizations } = useAuth();

    const handleListItemClick = (url) => {
        history.push(url);
    };

    const handleLogout = async () => {
        await logoutAction(history);
    };

    console.log('currentUser', currentUser);
    console.log('organizations', organizations);

    let userEmail = currentUser?.identity?.traits?.email;
    let orgName = organizations[0].name;

    const { pathname } = useLocation();

    return (
        <Box display='flex' flexDirection='column' justifyContent='space-between' flex='0 1 100%' style={{padding: 0, height: '100%'}}>
            <Box display='flex' flexDirection='column' justifyContent='space-between' className='side_menu'>
                <List>
                    {links.map((group, index) => (
                        <Fragment key={index}>
                            {group.map(({
                                url, label, icon
                            }, key) => (
                                <Tooltip key={`side-menu-${key}`} title={label} placement='right'>
                                    <ListItem className='side_menu--item' button selected={url === `/${pathname.split('/')[1].split('-')[0]}`} onClick={() => handleListItemClick(url, label)}>
                                        <ListItemIcon className='side_menu--icons'>{icon}</ListItemIcon>
                                        <ListItemText primary={label} />
                                    </ListItem>
                                </Tooltip>
                            ))}
                        </Fragment>
                    ))}
                </List>
            </Box>
            <Box display='flex' flexDirection='column' justifyContent='space-between' className='side_menu'>
                <List style={{padding: 0}}>
                    <Tooltip title='Logout' placement='right'>
                        <List>
                            <ListItem className='side_menu--item' >
                                <ListItemText primary={userEmail} />
                            </ListItem>
                            <ListItem className='side_menu--item' >
                                <ListItemText primary={orgName} />
                            </ListItem>
                        </List>
                    </Tooltip>
                </List>
            </Box>

            <Box display='flex' flexDirection='column' justifyContent='space-between' className='side_menu'>
                <List style={{padding: 0}}>
                    <Tooltip title='Logout' placement='right'>
                        <ListItem className='side_menu--item' button selected={'/logout' === `/${pathname.split('/')[1].split('-')[0]}`} onClick={handleLogout}>
                            <ListItemIcon className='side_menu--icons'><MeetingRoomTwoTone /></ListItemIcon>
                            <ListItemText primary='Logout' />
                        </ListItem>
                    </Tooltip>
                </List>
            </Box>
        </Box>
    );
};

export default SideMenu;
