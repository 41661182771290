/* eslint-disable react/display-name */
import React from 'react';
import { useParams } from 'react-router-dom';
import { Handle, Position } from 'reactflow';
import StepHeader from 'components/atoms/Step/StepHeader';
import { Typography  } from '@mui/material';
import { useJourneyStep } from 'api/Journeys/journey_queries';
import { useLocalStore  } from 'store/index';

const HANDLE_STYLE = {
    height: 13,
    width: 13,
    position: 'absolute',
    background: 'transparent', //'#E6F5FD',
    boxSizing: 'border-box',
    border: '2px solid transparent',
    bottom: '0px'
};

const WaitDraftNode = ({ data, status, handleSidebar }) => {
    const label = data?.wait_draft?.descriptive_label ?? data?.label_wait;

    return (
        <StepHeader stepData={data} status={status} handleSidebar={handleSidebar}>
            <Typography variant='caption' component='div' style={{fontWeight: 400, marginLeft: 7, fontSize: 12, marginRight: 5, maxWidth: '150px', overflowX: 'hidden'}}>
                {label}
            </Typography>
        </StepHeader>
    );
};

const WaitActiveNode = ({ data, status, handleSidebar }) => {
    const label = data?.wait?.descriptive_label;

    return (
        <StepHeader stepData={data} status={status} handleSidebar={handleSidebar}>
            <Typography variant='caption' component='div' style={{fontWeight: 400, marginLeft: 7, fontSize: 12, marginRight: 5, maxWidth: '150px', overflowX: 'hidden'}}>
                {label}
            </Typography>
        </StepHeader>
    );
};

const WaitNode = React.memo(({ data, isConnectable }) => {
    const { id } = useParams();
    const { isDisabled, stepId, status } = data;

    const stepData = useJourneyStep(id, stepId);

    const [getEdit, setContextSidebar] = useLocalStore((state) => [state.getEdit, state.setContextSidebar]);

    const editState = getEdit(id);

    const handleSidebar = (event) => {
        const payload = {
            open: true,
            context: 'Journeys',
            element: {
                type: stepData.type,
                stepId: data.stepId
            }
        };

        setContextSidebar(payload);
    };

    return (
        stepData.data ?
            <div className={!isDisabled ? 'flow-wait-node' : 'flow-wait-node disabled'}>
                <Handle
                    type='target'
                    position={Position.Top}
                    style={{ height: 0, border: 0, background: 'transparent' }}
                    isConnectable={isConnectable}
                />

                {editState?.state ? <WaitDraftNode handleSidebar={handleSidebar} status={status} data={stepData.data}/> : <WaitActiveNode handleSidebar={handleSidebar} status={status} data={stepData.data}/>}

                <Handle
                    type='source'
                    position={Position.Bottom}
                    id='match'
                    style={HANDLE_STYLE}
                    isConnectable={isConnectable}
                />
            </div>
            : <></>
    );
});

export default WaitNode;
