import React, { useState } from 'react';
import { Button, Popover } from '@mui/material';
import UnfoldMoreTwoToneIcon from '@mui/icons-material/UnfoldMoreTwoTone';

const MultipleSortButton = (props) => {
    const { content } = props;

    const [buttonAnchor, setButtonAnchor] = useState(null);
    const openColumnsList = (event) => {
        setButtonAnchor(event.currentTarget);
    };
    
    const handleCloseColumnsList = () => {
        setButtonAnchor(null);
    };
    
    const buttonOpen = Boolean(buttonAnchor);
    const id = buttonOpen ? 'simple-popover' : undefined;

    return (
        <div className='column_select'>
            <Button aria-describedby={id} variant='contained' margin='0' onClick={openColumnsList}>
                <UnfoldMoreTwoToneIcon fontSize='small' sx={{marginRight: 1}}/> Sort
            </Button>
            <Popover
            id={id}
            open={buttonOpen}
            anchorEl={buttonAnchor}
            onClose={handleCloseColumnsList}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
            className='column_select--content'
        >
                {content}
            </Popover>
        </div>
    );
};
export default MultipleSortButton;