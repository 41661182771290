import { createFromCall } from '../../util/Type';

const PREFIX = 'Context/';

export const CALLS = {
    SHOW_CONTEXT: `${PREFIX}SHOW_CONTEXT_START`
};

export const SHOW_CONTEXT_START = CALLS.SHOW_CONTEXT;
export const SHOW_CONTEXT_SUCCESS = createFromCall(CALLS.SHOW_CONTEXT, 'SUCCESS');
export const SHOW_CONTEXT_ERROR = createFromCall(CALLS.SHOW_CONTEXT, 'ERROR');
