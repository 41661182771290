import React from 'react';
import { Paper, Typography, List, ListItem, Divider } from '@mui/material';

const IssueDisplay = ({ issues }) => {
    return (
    <Paper elevation={3} style={{ padding: '20px', maxWidth: '600px', margin: '20px auto' }}>
        <Typography variant='h5' gutterBottom>
            Issues
        </Typography>
        <List>
            {issues.map((issue, index) => (
                <div key={index}>
                    <ListItem>
                        <div>
                            <Typography variant='h6'>{issue.detail}</Typography>
                            <Typography variant='subtitle1' color='textSecondary'>{issue.readable}</Typography>
                            <Typography variant='body2' color='error'>{issue.error?.message} </Typography>
                            {issue.error?.lines && issue.error.lines.map((line, lineIndex) => (
                                <Typography variant='body2' color='textSecondary' key={lineIndex}>
                                    Line {issue.error.lineno + lineIndex - 1}: {line}
                                </Typography>
                            ))}
                        </div>
                    </ListItem>
                    {index !== issues.length - 1 && <Divider />}
                </div>
            ))}
        </List>
    </Paper>
    );
};

export default IssueDisplay;
