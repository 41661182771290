import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { ConfirmProvider } from 'material-ui-confirm';



/***** Components *****/
import Basic from 'components/Templates/Basic';

import ProtectedRoute from './ProtectedRoute';

import { AuthProvider } from 'contexts/AuthContext';

import { ReactFlowProvider } from 'reactflow';



/***** Pages *****/
const Dashboard = lazy(() => import('./pages/Dashboard/Dashboard.js'));


const Journeys = lazy(() => import('./pages/Journeys/Journeys.js'));
const CreateJourney = lazy(() => import('./pages/Journeys/CreateJourney.js'));
const JourneyMessage = lazy(() => import('./pages/Journeys/JourneyMessage.js'));


const ImportSessionsPage = lazy( () => import('./pages/ImportSessions/ImportSessionsPage.js'));
const ImportSessionConfigure = lazy( () => import('./pages/ImportSessions/ImportSessionConfigure.js'));
const ImportSessionUploadCsv = lazy( () => import('./pages/ImportSessions/ImportSessionUploadCsv.js'));
const ImportSessionStatusPage = lazy( () => import('./pages/ImportSessions/ImportSessionStatusPage.js'));

const Campaigns = lazy(() => import('./pages/Campaigns/Campaigns.js'));
const Settings = lazy(() => import('./pages/Settings/Settings.js'));


const VmtaPool = lazy(() => import('./pages/Settings/VmtaPool.js'));
const VmtaPools = lazy(() => import('./pages/Settings/VmtaPools.js'));

const Library = lazy(() => import('./pages/Library/Library.js'));
const LoginPage = lazy(() => import('./pages/Login/LoginPage.js'));
const RegisterPage = lazy(() => import('./pages/Register/RegisterPage.js'));
const RecoveryPage = lazy(() => import('./pages/Recovery/RecoveryPage.js'));
const VerificationPage = lazy(() => import('pages/Verification/VerificationPage.js'));
const CreateOrganizationPage = lazy(() => import('./pages/CreateOrganizationPage/CreateOrganizationPage.js'));
const PrivacyPolicy = lazy(() => import('./pages/Static/Privacy.js'));


const VariantStrategy = lazy(() => import('./pages/Statistics/VariantStrategy.js'));

const BatchesPage = lazy(() => import('./pages/Batches/Batches.js'));
const SingleBatchPage = lazy(() => import('./pages/Batches/SingleBatchPage.js'));

const AllAudiencePage = lazy(() => import('./pages/Audiences/Audiences.js'));
const SearchSegment = lazy(() => import('./pages/Profiles/SearchSegment.js'));

// const StripeCheckout = lazy(() => import('./pages/Billing/StripeCheckout.js'));
const BillingSubscription = lazy(() => import('components/organisms/BillingSubscriptionStack/BillingSubscription.js'));

/*** Sub pages ***/
const CreateCampaign = lazy(() => import('./pages/Campaigns/PageCampaignEdit.js'));
const ScheduledCampaign = lazy(() => import('./pages/Campaigns/ScheduledCampaign.js'));
const CompletedCampaign = lazy(() => import('./pages/Campaigns/CompletedCampaign.js'));

const AudienceDynamicPage = lazy(() => import('./pages/Audiences/AudienceDynamic.js'));
const AudienceStaticPage = lazy(() => import('./pages/Audiences/AudienceStatic.js'));

/*** Utils pages ***/
const ErrorPage = lazy(() => import('./pages/UtilPages/ErrorPage.js'));
const Onboarding = lazy(() => import('./pages/UtilPages/Onboarding.js'));

/*** Examples ***/
// const QueryBuilderExample = lazy(() => import('./pages/Examples/QueryBuilderExample.js'));

const Root = () => {
    return (
        <Suspense>
            <BrowserRouter basename='/'>
                <AuthProvider>
                <ReactFlowProvider>
                <SnackbarProvider>
                    <Switch>
                        <Redirect exact from='/' to='/campaigns'/>
                        <Redirect exact from='/campaigns/create-organization' to='/create-organization'/>
                        {process.env.VERCEL_ENV === 'production' && <Redirect exact from='/dashboard' to='/register/onboarding'/> }
                        {process.env.VERCEL_ENV === 'development' && <Redirect exact from='/dashboard' to='/campaigns'/> }
                        <Redirect exact from='/logout' to='/login'/>

                        <ProtectedRoute exact path='/dashboard/:tab?' component={Dashboard} pageName='Dashboard' name='Dashboard' />

                        <ProtectedRoute exact path='/journeys/:id(\d+)' component={CreateJourney} pageName='Journeys' name='CreateJourney' />
                        <ProtectedRoute exact path='/journeys/:tab(\w+)?' component={Journeys} pageName='Journeys'/>
                        <ProtectedRoute exact path='/journeys/:id/message/:stepId/:step' component={JourneyMessage} pageName='Journeys' name='CreateJourney' />

                        <ProtectedRoute exact path='/campaigns/:id/audience-counts' component={lazy(() => import('./pages/Campaigns/CampaignAudiencesCounts'))} pageName='Completed Audiences Counts'/>

                        <ProtectedRoute exact path='/campaigns/:tab?' component={Campaigns} pageName='Campaigns' name='CampaignStatistics' />
                        <ProtectedRoute exact path='/batches' component={BatchesPage} pageName='Batches' name='Batches' />
                        <ProtectedRoute exact path='/batches/:id' component={SingleBatchPage} pageName='Batch' name='Batch' />

                        <ProtectedRoute exact path='/campaigns-completed' component={lazy(() => import('./pages/Campaigns/Completed.js'))} pageName='Completed Campaigns'/>
                        <ProtectedRoute exact path='/campaigns-draft' component={lazy(() => import('./pages/Campaigns/Draft.js'))} pageName='Completed Campaigns'/>


                        <ProtectedRoute exact path='/campaigns/scheduled/:id' component={ScheduledCampaign} pageName='Campaigns' name='EditCampaigns' />
                        <ProtectedRoute exact path='/campaigns/completed/:id' component={CompletedCampaign} pageName='Campaigns' name='CompletedCampaign' />
                        <ProtectedRoute exact path='/campaigns/:id/:step?/:substep?' component={CreateCampaign} pageName='Campaigns'/>

                        <ProtectedRoute exact path='/audiences' component={AllAudiencePage} pageName='Audiences' name='SingleAudience' />
                        <ProtectedRoute exact path='/audiences/dynamic/:id' component={AudienceDynamicPage} pageName='Audiences' name='Dynamic Audience' />
                        <ProtectedRoute exact path='/audiences/static/:id' component={AudienceStaticPage} pageName='Audiences' name='Static Audience' />

                        <ProtectedRoute exact path='/profiles/query' component={SearchSegment} pageName='Audiences' name='Search and Segment' />

                        <ProtectedRoute exact path='/import-sessions' component={ImportSessionsPage} pageName='Import Sessions' />
                        <ProtectedRoute exact path='/import-sessions/upload' component={ImportSessionUploadCsv} pageName='Import Session Start' />
                        <ProtectedRoute exact path='/import-sessions/:id/configure' component={ImportSessionConfigure} pageName='Import Session Configure' />
                        <ProtectedRoute exact path='/import-sessions/:id/status' component={ImportSessionStatusPage} pageName='Import Session Status' />

                        <ProtectedRoute exact path='/library/:tab?' component={Library} pageName='Library' />
                        <ProtectedRoute exact path='/register/onboarding' component={Onboarding} pageName='Onboarding' />

                        <ProtectedRoute exact path='/settings/:tab?' component={Settings} pageName='Settings'/>

                        <ProtectedRoute exact path='/upload-process-sessions' component={lazy(() => import('./pages/UploadProcessSessions/UploadProcessSessions.js'))} pageName='Upload Sessions'/>
                        <ProtectedRoute exact path='/upload-process-sessions/suppressions/upload' component={lazy(() => import('./pages/UploadProcessSessions/SuppressionUpload.js'))} pageName='Suppression Upload'/>

                        <ProtectedRoute exact path='/upload-process-sessions/:id' component={lazy(() => import('./pages/UploadProcessSessions/UploadProcessSession.js'))} pageName='Upload Session'/>


                        <ProtectedRoute exact path='/vmta-pools' component={VmtaPools} pageName='Vmta Pools'/>
                        <ProtectedRoute exact path='/vmta-pools/:id' component={VmtaPool} pageName='Vmta Pool'/>

                        <ProtectedRoute exact path='/analytics/variant-strategy' component={VariantStrategy} pageName='Variant Strategy'/>


                        <ProtectedRoute exact path='/admin/suppression-lists/optizmo' component={lazy(() => import('./pages/admin/suppression-lists/optizmo/SuppressionOptizmos.js'))} pageName='Optizmo Offers'/>
                        <ProtectedRoute exact path='/admin/complaints' component={lazy(() => import('./pages/admin/complaints/Complaints'))} pageName='Complaints'/>


                        {/*<ProtectedRoute exact path='/billing/checkout' component={StripeCheckout} pageName='Checkout'/>*/}
                        {/*<ProtectedRoute exact path='/billing/subscription' component={BillingSubscription} pageName='Checkout'/>*/}

                        <Route path='/register' render={props => <Basic main={<RegisterPage/>} {...props}/>}/>
                        <Route path='/login' render={props => <Basic main={<LoginPage/>} {...props}/>}/>
                        <Route path='/account-recovery' render={props => <Basic main={<RecoveryPage/>} {...props}/>}/>
                        <Route path='/verification' render={props => <Basic main={<VerificationPage/>} {...props}/>}/>
                        <Route path='/create-organization' render={props => <Basic main={<CreateOrganizationPage />} {...props}/>}/>

                        {/*<Route path={'/examples/query-builder'} render={props => <Basic main={<QueryBuilderExample />} {...props}/>}/>*/}

                        <Route path='/privacy-policy' render={props => <Basic main={ <PrivacyPolicy />} {...props}/>} />




                        <ProtectedRoute component={ErrorPage} />
                    </Switch>
                </SnackbarProvider>
                </ReactFlowProvider>
                </AuthProvider>
            </BrowserRouter>
        </Suspense>
    );
};

export default Root;
