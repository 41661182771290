import { createFromCall } from '../../util/Type';

const PREFIX = 'Organizations/';

export const CALLS = {
    FETCH_ORGANIZATIONS: `${PREFIX}FETCH_ORGANIZATIONS_START`,
    POST_ORGANIZATIONS: `${PREFIX}POST_ORGANIZATIONS_START`
};

export const FETCH_ORGANIZATIONS_START = CALLS.FETCH_ORGANIZATIONS;
export const FETCH_ORGANIZATIONS_SUCCESS = createFromCall(CALLS.FETCH_ORGANIZATIONS, 'SUCCESS');
export const FETCH_ORGANIZATIONS_ERROR = createFromCall(CALLS.FETCH_ORGANIZATIONS, 'ERROR');

export const POST_ORGANIZATIONS_START = CALLS.POST_ORGANIZATIONS;
export const POST_ORGANIZATIONS_SUCCESS = createFromCall(CALLS.POST_ORGANIZATIONS, 'SUCCESS');
export const POST_ORGANIZATIONS_ERROR = createFromCall(CALLS.POST_ORGANIZATIONS, 'ERROR');