import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAuth } from 'contexts/AuthContext';
import Layout from 'components/Templates/Layout';
import SideMenu from 'components/Templates/SideMenu';

const ProtectedRoute = ({ component: Component, componentType: ComponentType, pageName: name, edit, name: subName, ...rest }) => {
    const { currentUser, organizations } = useAuth();
    
    return (
        <Route
        {...rest}
        render={(props) =>
            (currentUser?.active && organizations?.length > 0) ? <Layout main={<Component edit={edit} subName={subName} />} pageName={name} menu={<SideMenu open={true} active={name}/>} {...props} />
                : currentUser?.active && !currentUser.identity.verifiable_addresses[0].verified ? <Redirect to='/verification' />
                    : (currentUser?.active && organizations?.length < 1) ? <Redirect to='create-organization' />
                        : (currentUser?.error || !currentUser) && <Redirect to='/login' />
      }
    />
    );
};

export default ProtectedRoute;
 