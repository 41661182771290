/* eslint-disable react/display-name */
import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Handle, Position } from 'reactflow';
import StepHeader from 'components/atoms/Step/StepHeader';
import { Typography  } from '@mui/material';
import { useJourneyStep } from 'api/Journeys/journey_queries';
import { useLocalStore } from 'store/index';

const HANDLE_STYLE = {
    height: 1,
    width: 1,
    position: 'absolute',
    background: 'transparent', //'#E6F5FD',
    boxSizing: 'border-box',
    border: '2px solid transparent',
    bottom: '0px'
};

const BranchDraftNode = ({ data, status, handleSidebar }) => {
    const label = data?.filter_draft?.descriptive_label ?? data?.description;

    return (
        <StepHeader stepData={data} status={status}  handleSidebar={handleSidebar}>
            <Typography variant='caption' component='div' style={{fontWeight: 400, marginLeft: 7, fontSize: 12, marginRight: 5}}>
                {label}
            </Typography>
        </StepHeader>
    );
};

const BranchActiveNode = ({ data, status, handleSidebar }) => {
    const label = data?.filter?.descriptive_label ?? data?.description;

    return (
        <StepHeader stepData={data} status={status}  handleSidebar={handleSidebar}>
            <Typography variant='caption' component='div' style={{fontWeight: 400, marginLeft: 7, fontSize: 12, marginRight: 5}}>
                {label}
            </Typography>
        </StepHeader>
    );
};

const BranchNode = memo(({ data, isConnectable }) => {
    const { id } = useParams();
    const { isDisabled, stepId, status } = data;

    const stepData = useJourneyStep(id, stepId);

    const [getEdit, setContextSidebar] = useLocalStore((state) => [state.getEdit, state.setContextSidebar]);
    const editState = getEdit(id);

    const handleSidebar = (event) => {
        if(event.target.id !== 'campaign-subject') {
            const payload = {
                open: true,
                context: 'Journeys',
                element: {
                    type: stepData.type,
                    stepId: data.stepId
                    // activeTab: payload.activeTab
                }
            };

            setContextSidebar(payload);
        }
    };

    return (
        stepData.data ?
            <div className={!isDisabled ? 'flow-branch-node' : 'flow-branch-node disabled'}>
                <Handle
                    type='target'
                    position={Position.Top}
                    style={{ left: '50%', height: 0, border: 0, background: 'transparent' }}
                    isConnectable={isConnectable}
                />

                {editState?.state ? <BranchDraftNode data={stepData.data} status={status} handleSidebar={handleSidebar} /> : <BranchActiveNode data={stepData.data} status={status} handleSidebar={handleSidebar} /> }

                <Handle
                    type='source'
                    position={Position.Bottom}
                    id='match'
                    style={{...HANDLE_STYLE, left: '38%'}}
                    isConnectable={isConnectable}
                />
                <Handle
                    type='source'
                    position={Position.Bottom}
                    id='no_match'
                    style={{...HANDLE_STYLE, left: '60%'}}
                    isConnectable={isConnectable}
                />
            </div>
            : <></>
    );
});

export default BranchNode;
